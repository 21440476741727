import { IReportConfiguration } from '@src/types/report_service/report_configurations';
import { camelizeKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

const getReportConfigurations = (): Promise<IReportConfiguration[]> => {
  return apiGet('/reports/api/v1/configurations/report_configuration')
    .then((data) => camelizeKeys(data.configurations) as IReportConfiguration[]);
};

export {
  getReportConfigurations,
};
