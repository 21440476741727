module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (this.child_items && this.child_items.length > 0) {
        __out.push('\n  <div class="nav-item header-js">\n    <div class="display-flex align-items-center pointer font-semi-bold ');
        __out.push(__sanitize(this.editable ? 'item-box' : ''));
        __out.push('">\n      <i class="fa ');
        __out.push(__sanitize(this.childrenVisible ? 'fa-caret-up' : 'fa-caret-down'));
        __out.push(' font-16 ');
        __out.push(__sanitize(this.editable ? '' : 'p-r-10'));
        __out.push('"></i>\n      ');
        if (this.editable) {
          __out.push('\n        <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline display-flex align-items-center">\n          <input type="radio" id="');
          __out.push(__sanitize(this.id));
          __out.push('" name="radio-period" class="radio-item" value="');
          __out.push(__sanitize(this.id));
          __out.push('" ');
          __out.push(__sanitize(this.id === this.curParentItemId ? 'checked' : ''));
          __out.push('>\n          <label class=\'no-parent-name-label m-l-4\' for="');
          __out.push(__sanitize(this.id));
          __out.push('">');
          __out.push(__sanitize(this.name));
          __out.push('</label>\n        </div>\n      ');
        } else {
          __out.push('\n        <div class="inline-block in-black m-l-4">');
          __out.push(__sanitize(this.name));
          __out.push('</div>\n      ');
        }
        __out.push('\n    </div>\n  </div>\n  <div class="display-flex-column items-wrapper ');
        if (!this.childrenVisible) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('"></div>\n    <div class="child-item-list"></div>\n    <div class="nav-item p-l-20 hidden">\n      <a class="pointer font-semi-bold show-wrapper">\n        <i class="icon icon-o-plus in-blue-400 font-16"></i>\n        <span class="inline-block in-blue-400 m-l-30">Add New Item</span>\n      </a>\n      <div class="display-flex align-items-center edit-wrapper height-100-percent">\n        <input class="value-edit-input form-control m-t-auto m-b-auto m-l-52" type="text" value="" placeholder="Add New Item">\n        <button type=\'button\' class="btn btn-blue add-item-js m-l-10 m-r-15">Add Item</button>\n      </div>\n    </div>\n  </div>\n');
      } else {
        __out.push('\n  <div class=\'display-flex align-items-center font-semi-bold item-divider pointer item-box\'>\n    ');
        if (this.editable) {
          __out.push('\n      <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n        <input type="radio" id="');
          __out.push(__sanitize(this.id));
          __out.push('" name="radio-period" class="radio-item" value="');
          __out.push(__sanitize(this.id));
          __out.push('" ');
          __out.push(__sanitize(this.id === this.curChildItemId ? 'checked' : ''));
          __out.push('>\n        <label class=\'no-parent-name-label m-l-4\' for="');
          __out.push(__sanitize(this.id));
          __out.push('">');
          __out.push(__sanitize(this.name));
          __out.push('</label>\n      </div>\n      <span class=\'m-l-auto m-r-15\'>');
          __out.push(__sanitize(this.item_account_count));
          __out.push(' Linked</span>\n    ');
        } else {
          __out.push('\n      <label class=\'no-parent-name-label m-l-40\' for="');
          __out.push(__sanitize(this.id));
          __out.push('">');
          __out.push(__sanitize(this.name));
          __out.push('</label>\n    ');
        }
        __out.push('\n  </div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}