import React, { useCallback, useMemo, useState } from 'react';

import {
  useCreateFinancialInstitutionConnection,
  useGetFinancialInstitutionConnection,
} from '@src/hooks/queries/financial_institution_connection';
import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';

import {
  IConnectNewFinancialInstitutionData,
  // eslint-disable-next-line max-len
} from '@src/components/financial_institution_connection_center/financial_institution_connection_center_details/connect_financial_institution/schema';
import {
  useConnectAggregatorAccount,
  // eslint-disable-next-line max-len
} from '@src/components/financial_institution_connection_center/financial_institution_connection_center_details/hooks';
import { Button } from '@src/components/ui_v2/buttons';
import { SuccessIcon } from '@src/components/utils/icomoon';
import { BankPaymentMethodIcon } from '@src/components/utils/img_icons';

import FinListView from './fin_list_view';

import styles from './styles.module.scss';

interface IAuthorizedSignatoryViewProps {
  invitation: ISelfOnboardingInvitation,
  isSuccess: boolean,
}

const FinDetailsView = ({
  invitation,
  isSuccess,
}: IAuthorizedSignatoryViewProps) => {
  const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(isSuccess);

  const handleGotoHome = useCallback(() => {
    Backbone.history.navigate('/dashboard', { trigger: true });
  }, []);

  const handleSubmit = useCallback(() => {
    Backbone.history.navigate('/self_onboarding?step=5&success=1', { trigger: false, replace: true });
    setShowSuccessBanner(true);
  }, [setShowSuccessBanner]);

  const query = useGetFinancialInstitutionConnection({
    filter: { management_group_id: invitation.managementGroupId },
  });

  const financialInstitutionConnections = useMemo(() => {
    return query.data?.financialInstitutionConnections || [];
  }, [query.data]);

  const create = useCreateFinancialInstitutionConnection();
  const { mutate: createFinancialInstitution } = create;

  const connectNewFinancialInstitution = useCallback((
    data: IConnectNewFinancialInstitutionData,
  ) => {
    createFinancialInstitution({ financialInstitutionConnection: data });
  }, [createFinancialInstitution]);

  const {
    connectAggregator
  } = useConnectAggregatorAccount({ onAggregatorConnected: connectNewFinancialInstitution });

  if (showSuccessBanner) {
    return (
      <div className={ styles['connect-fin-empty'] }>
        <SuccessIcon fontSize={ 80 } mt={ 80 } />
        <h2 className="in-black m-t-40 m-b-30">
          Great, you&apos;re all set to use Docyt now.
        </h2>
        <Button
          className={ styles['action-button'] }
          variant="link"
          onClick={ handleGotoHome }
        >
          Go to Home
        </Button>
      </div>
    );
  }

  if (financialInstitutionConnections.length === 0) {
    return (
      <div className={ styles['connect-fin-empty'] }>
        <BankPaymentMethodIcon mt={ 80 } size="150px" />
        <span className="m-t-10">Connect all your business bank accounts for Docyt to fetch daily</span>
        <span className="m-t-5 m-b-50">transactions effortlessly.</span>
        <Button
          className={ styles['action-button'] }
          variant="link"
          onClick={ () => connectAggregator({ managementGroupId: invitation.managementGroupId }) }
        >
          + Connect Financial Institution
        </Button>
      </div>
    );
  }

  return (
    <div className={ styles['details-view'] }>
      <div className={ styles['fin-connection-list-container'] }>
        <FinListView financialInstitutionConnections={ financialInstitutionConnections } />
      </div>
      <div className="setup-client-footer">
        <Button
          className={ styles['action-link'] }
          variant="link"
          onClick={ handleSubmit }
        >
          Save
        </Button>
        <Button
          className={ styles['action-button'] }
          variant="link"
          onClick={ () => connectAggregator({ managementGroupId: invitation.managementGroupId }) }
        >
          + Connect Financial Institution
        </Button>
      </div>
    </div>
  );
};

export default FinDetailsView;
