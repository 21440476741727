import React, { useMemo } from 'react';

import classNames from 'classnames';
import { useRecoilValue } from 'recoil';

import { IReport } from '@src/types/report_service/report';
import { IReportColumn } from '@src/types/report_service/report_column';

import { pickMultiMonths } from '@src/components/business_reports/atoms';
import Table from '@src/components/ui_v2/table';

import { IHeaderColumn, isStoreManagersReport } from '../../hooks';

interface ITableHeaderProps {
  report: IReport;
  headerTopRowColumns: IHeaderColumn[];
  headerMiddleRowColumns: IHeaderColumn[];
  headerBottomRowColumns: IHeaderColumn[];
  multiMonthColumns: IReportColumn[];
  ptdColumns: IReportColumn[];
  ytdColumns: IReportColumn[];
}

const TableHeader = ({
  report,
  headerTopRowColumns,
  headerMiddleRowColumns,
  headerBottomRowColumns,
  multiMonthColumns,
  ptdColumns,
  ytdColumns,
}: ITableHeaderProps): JSX.Element => {
  const showMultiMonths = useRecoilValue(pickMultiMonths);

  const rowSpan = useMemo(() => {
    if (headerBottomRowColumns.length === 0) return 1;
    if (headerMiddleRowColumns.length !== 0) return 3;
    return 2;
  }, [headerBottomRowColumns.length, headerMiddleRowColumns.length]);

  const colSpan = useMemo(() => {
    if (showMultiMonths) return multiMonthColumns.length;
    if (isStoreManagersReport(report)) return ptdColumns.length / 2;

    return ptdColumns.length;
  }, [multiMonthColumns.length, ptdColumns.length, report, showMultiMonths]);

  const colSpanBottom = useMemo(() => {
    return ytdColumns.length !== 0 && colSpan !== 0 && !showMultiMonths ? colSpan - ytdColumns.length + 1 : 1;
  }, [colSpan]);

  const classes = classNames(`header-cell-${headerBottomRowColumns.length || headerTopRowColumns.length}-${colSpan}`);

  return (
    <Table.Head>
      <Table.Row>
        <Table.EmptyHCell className="line-item-cell" rowSpan={ rowSpan } />
        {headerTopRowColumns.map((column: IHeaderColumn) => (
          <Table.HCell key={ column.key } className={ classes } colSpan={ colSpan }>
            {column.name}
          </Table.HCell>
        ))}
      </Table.Row>

      { headerMiddleRowColumns.length !== 0 && (
        <Table.Row>
          { headerMiddleRowColumns.map((column: IHeaderColumn) => (
            <Table.HCell key={ column.key } className={ classes } colSpan={ colSpan }>
              {column.name}
            </Table.HCell>
          )) }
        </Table.Row>
      ) }

      {headerBottomRowColumns.length !== 0 && (
        <Table.Row>
          {headerBottomRowColumns.map((column: IHeaderColumn, key: number) => (
            <Table.HCell
              key={ column.key }
              className={ classes }
              {...(key === headerBottomRowColumns.length - 1 ? { colSpan: colSpanBottom } : {})}
              scope="col"
              style={ {textAlign: 'center'} }
            >
              {column.name}
            </Table.HCell>
          ))}
        </Table.Row>
      )}
    </Table.Head>
  );
};

export default TableHeader;
