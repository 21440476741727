import { TID } from '@src/types/common';
import { IManagementGroup } from '@src/types/management_groups';
import { IUserManagementGroupSetting } from '@src/types/user_management_group_settings';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from './helpers';

interface IGetManagementGroupsParams {
  filter?: object,
  page?: number,
  perPage?: number,
}

interface IGetManagementGroupsResponse {
  meta: {
    totalCount: number,
  },
  collection: IManagementGroup[],
}

interface IGetManagementGroupResponse {
  managementGroup?: IManagementGroup,
}

interface IDeleteManagementGroupParams {
  id: TID,
}

interface IAddManagementGroupParams {
  name: string,
}

interface IUpdateManagementGroupParams {
  id: TID,
  name: string,
}

interface IGetManagementGroupByBusinessIdResponse {
  managementGroup?: IManagementGroup,
}

interface IGetManagementGroupDefaultAggregatorResponse {
  managementGroupDefaultAggregator: {
    managementGroupId: TID,
    defaultAggregator: 'plaid' | 'quiltt'
  }
}

const getManagementGroups = (
  params: IGetManagementGroupsParams,
): Promise<IGetManagementGroupsResponse> => {
  return apiGet(
    '/api/v1/management_groups',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.managementGroups,
    } as IGetManagementGroupsResponse;
  });
};

const getUserManagementGroupSettings = (
  parentData: any,
): Promise<any> => {
  return apiGet(
    '/api/v1/user_management_group_settings',
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    const managementGroups = parentData
      .managementGroups as IManagementGroup[];
    const ids = (
      cdata.userManagementGroupSettings as IUserManagementGroupSetting[]
    ).map((item) => item.managementGroupId) || [];
    managementGroups.forEach((managementGroup) => {
      managementGroup.kpiNotificationEnabled = !!ids.includes(managementGroup.id);
    });

    return {
      meta: {
        totalCount: parentData.count,
      },
      collection: managementGroups,
    };
  });
};

const getManagementGroupsWithNotificationSetting = (
  params: IGetManagementGroupsParams,
): Promise<IGetManagementGroupsResponse> => {
  return apiGet(
    '/api/v1/management_groups',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return getUserManagementGroupSettings(cdata);
  });
};

const getManagementGroup = (id?: TID): Promise<IGetManagementGroupResponse> => {
  return apiGet(
    `/api/v1/management_groups/${id}`,
  ).then((data) => {
    return {
      managementGroup: camelizeKeys(data.management_group),
    } as IGetManagementGroupResponse;
  });
};

const getManagementGroupByBusinessId = (
  businessId?: TID,
): Promise<IGetManagementGroupByBusinessIdResponse> => {
  return apiGet(
    '/api/v1/management_groups/by_business_id',
    underscoreKeys({ businessId }),
  ).then((data) => {
    return {
      managementGroup: camelizeKeys(data.management_group),
    } as IGetManagementGroupByBusinessIdResponse;
  });
};

const deleteManagementGroup = (
  params: IDeleteManagementGroupParams,
): Promise<void> => {
  return apiDelete(
    `api/v1/management_groups/${params.id}`,
  );
};

const addManagementGroup = (
  params: IAddManagementGroupParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/management_groups',
    underscoreKeys(params),
  );
};

const updateManagementGroup = (
  params: IUpdateManagementGroupParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/management_groups/${params.id}`,
    underscoreKeys(params),
  );
};

// this api call only made to the server when quiltt-integration feature flag is on
const getManagementDefaultAggregator = (
  managementGroupId: TID,
): Promise<IGetManagementGroupDefaultAggregatorResponse> => {
  return apiGet(
    'banking/api/v1/banking_management_groups/for_management_group',
    underscoreKeys({ managementGroupId }),
  ).then((data) => {
    return {
      managementGroupDefaultAggregator: camelizeKeys(data.management_group_default_aggregator),
    } as IGetManagementGroupDefaultAggregatorResponse;
  });
};

export {
  IGetManagementGroupsParams,
  IGetManagementGroupsResponse,
  IGetManagementGroupByBusinessIdResponse,
  IDeleteManagementGroupParams,
  IAddManagementGroupParams,
  IUpdateManagementGroupParams,
  IGetManagementGroupResponse,
  getManagementGroups,
  getManagementGroupByBusinessId,
  deleteManagementGroup,
  addManagementGroup,
  updateManagementGroup,
  getManagementGroup,
  getManagementGroupsWithNotificationSetting,
  getManagementDefaultAggregator,
  IGetManagementGroupDefaultAggregatorResponse,
};
