import React, { useCallback } from 'react';

import { Button, IButtonProps } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

import Progress from './bulk_action_progress';
import Result from './bulk_action_result';
import { IBulkActionStatus } from './hooks';

interface IBulkActionContentProps<TRequestParams, TRequestResponse> {
  itemsTitle: string,
  status: IBulkActionStatus<TRequestParams, TRequestResponse>,
  result?: (status: IBulkActionStatus<any, any>) => React.ReactNode
  onCancel: () => void,
  onDone: (status?: IBulkActionStatus<any, any>) => void,
}

const BulkActionContent = <TRequestParams, TRequestResponse>({
  itemsTitle,
  status,
  result,
  onCancel,
  onDone,
}: IBulkActionContentProps<TRequestParams, TRequestResponse>) => {
  const isFinished = status.status === 'finished';

  const buttonProps: IButtonProps = isFinished
    ? { bsColor: 'blue' }
    : { bsStyle: 'danger' };

  const handleClick = useCallback(() => {
    if (isFinished) {
      onDone(status);
    } else {
      onCancel();
    }
  }, [isFinished, onCancel, onDone, status]);

  return (
    <>
      <Modal.Body>
        { status.status === 'finished' && (result ? result(status) : <Result status={ status } />) }
        { status.status !== 'finished' && (
          <Progress
            doneCount={ status.successCount + status.failedCount }
            itemsTitle={ itemsTitle }
            totalCount={ status.totalCount }
          />
        ) }
      </Modal.Body>
      <Modal.Footer>
        <Button
          { ...buttonProps }
          title={ isFinished ? 'Close' : 'Stop' }
          onClick={ handleClick }
        />
      </Modal.Footer>
    </>
  );
};

export {
  IBulkActionStatus,
  BulkActionContent as default,
};
