import React, { useCallback, useEffect, useState } from 'react';

import toastr from '@lib/toastr';
import {
  useCreateFinancialInstitutionConnection,
} from '@src/hooks/queries/financial_institution_connection';
import { useGetManagementGroupDefaultAggregator } from '@src/hooks/queries/management_groups';
import { LDFeatureForUserEnabled } from '@src/utils/config';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import ConnectNewFinancialInstitutionForm from './connect_new_financial_institution_form';
import { IConnectNewFinancialInstitutionData } from './schema';
import { useConnectAggregatorAccount } from '../hooks';

interface IConnectNewFinancialInstitutionModalProps {
  show: boolean,
  onDone: () => void;
  onCancel: () => void;
}

const ConnectNewFinancialInstitutionModal = ({
  show,
  onDone,
  onCancel,
}: IConnectNewFinancialInstitutionModalProps): JSX.Element => {

  const [accountData, setAccountData] = useState<IConnectNewFinancialInstitutionData>()

  const create = useCreateFinancialInstitutionConnection();
  const {data: defaultAggregatoryQuery, error, isError} = useGetManagementGroupDefaultAggregator(accountData?.managementGroupId);
  const { mutate: createFinancialInstitution } = create;

  const connectNewFinancialInstitution = useCallback((
    data: IConnectNewFinancialInstitutionData,
  ) => {
    createFinancialInstitution({ financialInstitutionConnection: data }, {
      onSuccess: onDone,
    });
  }, [createFinancialInstitution, onDone]);

  const {
    connectAggregator,
    generateLinkMutation,
  } = useConnectAggregatorAccount({ onAggregatorConnected: connectNewFinancialInstitution });

  useEffect(()=>{
    if(defaultAggregatoryQuery?.managementGroupDefaultAggregator && accountData) {
      const { managementGroupDefaultAggregator } = defaultAggregatoryQuery
      connectAggregator(accountData, managementGroupDefaultAggregator.defaultAggregator);
    }
  },[defaultAggregatoryQuery, accountData, connectAggregator])
 
  // applied check for error while making api call to get default aggregator
  if(isError && error){
    toastr.error(error.message, 'Error');
  }

  const handleSubmit = useCallback((data: IConnectNewFinancialInstitutionData) => {
    // plaid will be replace with the dynamic value once management group api start sending aggregator
    LDFeatureForUserEnabled(window.Docyt.Common.Constants.QUILTT_INTEGRATION, String(window.Docyt.currentAdvisor.id)).then((isQuiltEnabled) => {
      if (isQuiltEnabled) {
        setAccountData(data);
      } else {
        connectAggregator(data);
      }
    });
  }, [connectAggregator]);

  return (
    <>
      <MutationStatus mutation={ generateLinkMutation } />
      <MutationStatus mutation={ create } successMessage="New Financial Institution Connection was created successfully" />
      <Modal.Form
        className="connect-financial-institution-modal"
        proceedTitle="Continue"
        show={ show }
        title="Select Management Group"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <ConnectNewFinancialInstitutionForm
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

export default ConnectNewFinancialInstitutionModal;
