import * as ld from 'launchdarkly-js-client-sdk';

import { TFeature } from '@src/types/env_config';

const isFeatureEnabled = (feature: TFeature): boolean => {
  return Boolean(window.configData.features && window.configData.features[feature]);
};

const LDFeatureValueForBusiness = (
  feature: string,
  businessId: string,
): boolean | undefined => {
  const LDFeature = window.configData.launch_darkly_enabled_features_for_business.find((ldFeature) => String(ldFeature.key) === `LDFeature-${feature}-${businessId}`);

  return LDFeature?.value;
};

const LDFeatureForBusinessEnabled = (
  feature: string,
  businessId: string,
): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    const client: ld.LDClient = ld.initialize(window.configData.launch_darkly.client_side_id, { key: `business-${businessId}` });

    client.on('ready', () => {
      const flagValue = client.variation(feature, false);
      if (LDFeatureValueForBusiness(feature, businessId) === undefined) {
        window.configData.launch_darkly_enabled_features_for_business.push({
          key:   `LDFeature-${feature}-${businessId}`,
          value: flagValue,
        });
      }
      resolve(flagValue);
    });
  });
};

const LDFeatureValueForUser = (
  feature: string,
  userId: string,
): string | undefined => {
  const LDFeature = window.configData.launch_darkly_enabled_features_for_user.find((ldFeature) => ldFeature.key === `LDFeature-${feature}-${userId}`);

  return LDFeature?.value;
};

const LDFeatureForUserEnabled = (
  feature: string,
  userId: string,
): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    const client: ld.LDClient = ld.initialize(window.configData.launch_darkly.client_side_id, { key: `user-${userId}` });

    client.on('ready', () => {
      const flagValue = client.variation(feature, false);
      if (LDFeatureValueForUser(feature, userId) === undefined) {
        window.configData.launch_darkly_enabled_features_for_user.push({
          key:   `LDFeature-${feature}-${userId}`,
          value: flagValue,
        });
      }
      resolve(flagValue);
    });
  });
};

const fetchLDBusinessFeature = (businessId: string, feature: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const cachedFlag = LDFeatureValueForBusiness(feature, businessId);
    if (cachedFlag !== undefined) {
      resolve(cachedFlag);
      return;
    }

    LDFeatureForBusinessEnabled(feature, businessId).then((res) => {
      resolve(res);
    });
  });
};

const isReportBTFServiceEnabled = (): boolean => {
  return window.configData.launch_darkly_enabled_global_btf_service_api_list.length > 0;
};

const isReportBTFEnabledKey = (key: number): boolean => {
  return window.configData.launch_darkly_enabled_global_btf_service_api_list.includes(key)
};

const getReportBTFEndPoint = (key: number): string => {
  const isBTFEnabledKey = window.configData.launch_darkly_enabled_global_btf_service_api_list.includes(key);
  let endPoint = 'reports';
  if(isBTFEnabledKey){
    endPoint = 'report-new';
  }
  return endPoint;
};

export {
  fetchLDBusinessFeature,
  getReportBTFEndPoint,
  isFeatureEnabled,
  isReportBTFEnabledKey,
  isReportBTFServiceEnabled,
  LDFeatureForBusinessEnabled,
  LDFeatureValueForBusiness,
  LDFeatureForUserEnabled,
  LDFeatureValueForUser,
};
