/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';

import moment from 'moment';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useExportReportAsExcel } from '@src/hooks/queries/report_service/custom_reports';
import { API_DATE_FORMAT } from '@src/utils/date_helpers';

import { MonthPickerInput } from '@src/components/ui/form';
import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

interface IMultiEntityDownloadConsolidatedReportProps extends IUseModalProps {
  reportIds: string[],
  onCreateExportData: (result: boolean) => void,
}

const MultiEntityDownloadConsolidatedReport = ({
  reportIds,
  isOpen,
  onCancel,
  onDone,
  onCreateExportData,
}: IMultiEntityDownloadConsolidatedReportProps) => {
  let isStartDate = false;
  let isEndDate = false;

  const downloadReports = useExportReportAsExcel();
  const { mutate } = downloadReports;

  const [startDateValue, setStartDateValue] = useState<string | undefined>(undefined);
  const [endDateValue, setEndDateValue] = useState<string | undefined>(undefined);

  const handleStartDateChange = useCallback((value: string | undefined) => {
    if (!value) return;

    const startDate = moment(`${value}-01`).startOf('month').format(API_DATE_FORMAT);
    setStartDateValue(startDate);

    if (endDateValue && isStartDate && moment(startDate).isAfter(moment(endDateValue))) {
      setEndDateValue(startDate);
      isStartDate = false;
    }

    isStartDate = true;
  }, [setStartDateValue, endDateValue, setEndDateValue]);

  const handleEndDateChange = useCallback((value: string | undefined) => {
    if (!value) return;

    const endDate = moment(`${value}-01`).endOf('month').format(API_DATE_FORMAT);
    setEndDateValue(endDate);

    if (startDateValue && isEndDate && moment(endDate).isBefore(moment(startDateValue))) {
      setStartDateValue(endDate);
      isEndDate = false;
    }

    isEndDate = true;
  }, [setStartDateValue, startDateValue, setEndDateValue]);

  const handleProceed = useCallback(() => {
    mutate(
      {
        exportType: 'multi_consolidated_report',
        startDate:  startDateValue as string,
        endDate:    endDateValue as string,
        reportIds,
        filter:     {
          industryId: 9,
        },
      },
      {
        onSuccess: () => {
          onCreateExportData(true);
          setStartDateValue(undefined);
          setEndDateValue(undefined);
          onDone();
        },
      },
    );
  }, [onDone, startDateValue, endDateValue, mutate, onCreateExportData]);

  const handleCancel = useCallback(() => {
    setStartDateValue(undefined);
    setEndDateValue(undefined);
    onCancel();
  }, [onCancel, setStartDateValue, setEndDateValue]);

  return (
    <>
      <Modal.Standard
        isProceedDisabled={ !startDateValue || !endDateValue }
        proceedTitle="Download"
        show={ isOpen }
        title="Download Consolidated Report"
        onCancel={ handleCancel }
        onProceed={ handleProceed }
      >
        {() => (
          <div className="report-type-container display-flex-column m-t-48 m-b-8">
            <div className="display-flex align-items-center p-l-50 p-r-50">
              <span className="font-16 m-r-20" style={ { textAlign: 'right', width: '100%' } }>
                Select Period:
              </span>
              <MonthPickerInput
                className="m-r-10"
                options={ { endDate: new Date(), format: 'M yyyy' } }
                placeholder="Start Date"
                value={ startDateValue }
                onChange={ handleStartDateChange }
              />
              <MonthPickerInput
                options={ { endDate: new Date(), format: 'M yyyy' } }
                placeholder="End Date"
                value={ endDateValue }
                onChange={ handleEndDateChange }
              />
            </div>
            <div className="display-flex align-items-center p-l-50 p-r-50 m-t-25">
              <span className="font-16 m-r-20" style={ { textAlign: 'right', width: '42%' } }>
                Industry:
              </span>
              <span className="font-bold" style={ { width: '100%' } }>
                Hotel
              </span>
            </div>
          </div>
        )}
      </Modal.Standard>
      <MutationStatus mutation={ downloadReports } />
    </>
  );
};

const useMultiEntityDownloadConsolidatedReportModal =
  makeUseModal(MultiEntityDownloadConsolidatedReport);

export {
  IMultiEntityDownloadConsolidatedReportProps,
  useMultiEntityDownloadConsolidatedReportModal,
  MultiEntityDownloadConsolidatedReport as default,
};
