import moment from 'moment';

import { useReconciliationPaymentAccountContext } from '@src/hooks/contexts/reconciliation_payment_account_context';
import { IBankAccountReconciliation, IBankAccountReconciliationItem, IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';
import { IBankStatement } from '@src/types/bank_statements';
import { TAmount } from '@src/types/common';
import { amountToNumber } from '@src/utils/amount_helper';
import { API_DATE_FORMAT } from '@src/utils/date_helpers';
import { camelizeKeys } from '@src/utils/transform_keys';
interface ItemTypesMap {
  [key: string]: IBankAccountReconciliationItem,
}

const isVerified = (bankStatement: IBankStatement) => {
  const VERIFIED = window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED;
  return bankStatement?.state === VERIFIED;
};

const getReconciliationDateRange = (
  reconciliation: IBankAccountReconciliation,
  bankStatement: IBankStatement,
) => {
  const { year, month } = reconciliation;

  let startDate = moment([year, month - 1]).format(API_DATE_FORMAT);
  let endDate = moment(startDate).endOf('month').format(API_DATE_FORMAT);

  if (bankStatement !== null) {
    startDate = bankStatement.startingDate;
    endDate = bankStatement.closingDate;
  }

  return [startDate, endDate];
};

const buildItemTypesFromArray = (
  items: IBankAccountReconciliationItem[],
): IBankAccountReconciliationItemTypes => {
  const reducer = (memo: ItemTypesMap, obj: IBankAccountReconciliationItem): ItemTypesMap => {
    memo[obj.itemType as string] = obj;
    return memo;
  };
  const itemTypesMap = items.reduce(reducer, {} as ItemTypesMap);

  return camelizeKeys(itemTypesMap) as IBankAccountReconciliationItemTypes;
};

const nonZeroAmount = (amount: TAmount | undefined | null): boolean => {
  if (!amount) return false;

  return Number(`${amount}e2`) !== 0.0;
};

// eslint-disable-next-line max-len
const amountFormatterX = (amount: TAmount | number | undefined | null) => {
  const numberAmount = amountToNumber(amount);

  return numberAmount
    .toLocaleString(
      'en-US',
      {
        style:                 'currency',
        currency:              'USD',
        minimumFractionDigits: 2,
      },
    );
};

// eslint-disable-next-line max-len
const amountFormatter = (amount: TAmount | number | undefined | null) => {
  return amountFormatterX(amount);
};

const usePaymentAccountName = () => {
  const reconciliationPaymentAccount = useReconciliationPaymentAccountContext();
  const PaymentAccountIds = reconciliationPaymentAccount.paymentAccounts;

  let key;
  let value;

  if ( PaymentAccountIds.length > 1 ) {
    key = 'payment_account_id';
    value = PaymentAccountIds.find( account => account.name === reconciliationPaymentAccount.name )?.id;
  } else {
    key = 'reconciliation_payment_account_id';
    value = reconciliationPaymentAccount.id;
  }

  return {
    [ key ]: value,
    'dropdown_field[0]': value,
  };
};


export {
  buildItemTypesFromArray,
  nonZeroAmount,
  amountFormatter,
  amountFormatterX,
  isVerified,
  getReconciliationDateRange,
  usePaymentAccountName,
};
