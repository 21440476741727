import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetRevenueReportTypesParams,
  IGetRevenueReportTypesResponse,
  getRevenueReportTypes,
} from '@src/requests/revenue_report_types';

const useGetRevenueReportTypes = (params: IGetRevenueReportTypesParams) => {
  return useQuery<IGetRevenueReportTypesResponse, Error>(
    [QueryKey.revenueReportTypes, params],
    () => getRevenueReportTypes(params),
  );
};

export {
  useGetRevenueReportTypes,
};
