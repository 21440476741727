import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useMetricsServiceContext } from '@src/hooks/contexts/metrics_service_context';

import ServiceMenu from '@src/components/common/service_menu';
import ServiceSidebar from '@src/components/ui/service_sidebar';
import {
  BusinessMetricIcon,
  IndustryMetricIcon,
} from '@src/components/utils/icomoon';

const MetricsServiceSideMenu = () => {
  const business = useBusinessContext();
  const service = useMetricsServiceContext();

  return (
    <ServiceSidebar
      serviceMenu={ (
        <ServiceMenu
          service={ service }
          serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.METRICS_SERVICE }
        />
      ) }
    >
      <ServiceSidebar.SubMenu
        expandedKey="metrics_expanded"
        icon={ <BusinessMetricIcon /> }
        title="Business Metric"
      >
        <ServiceSidebar.Item
          href={ `/businesses/${business.id}/metrics_service` }
          icon={ <IndustryMetricIcon /> }
          title="Industry Metrics"
        />
      </ServiceSidebar.SubMenu>
    </ServiceSidebar>
  );
};

export default MetricsServiceSideMenu;
