import React, { useCallback } from 'react';

import {
  useUpdateFinancialInstitutionConnection,
} from '@src/hooks/queries/financial_institution_connection';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';
import { LDFeatureForUserEnabled } from '@src/utils/config';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Content from './content';
import { IConnectNewFinancialInstitutionData } from './schema';
import { useConnectAggregatorAccount } from '../hooks';

interface IUpdateFinancialInstitutionModalProps {
  show: boolean,
  financialInstitutionConnections: IFinancialInstitutionConnection[],
  onDone: () => void,
  onCancel: () => void,
  onConnectNewFinancialInstitution: () => void;
}

const UpdateFinancialInstitutionModal = ({
  financialInstitutionConnections,
  show,
  onDone,
  onCancel,
  onConnectNewFinancialInstitution,
}: IUpdateFinancialInstitutionModalProps): JSX.Element => {
  const update = useUpdateFinancialInstitutionConnection();
  const { mutate: updateFinancialInstitution } = update;

  const connectNewFinancialInstitution = useCallback((
    data: IConnectNewFinancialInstitutionData,
  ) => {
    updateFinancialInstitution({
      id:                             data.id,
      financialInstitutionConnection: data,
    }, {
      onSuccess: onDone,
    });
  }, [updateFinancialInstitution, onDone]);

  const {
    reconnectToAggregator,
    generateLinkMutation,
  } = useConnectAggregatorAccount({ onAggregatorConnected: connectNewFinancialInstitution });

  const handleSelectFinancialInstitution = useCallback((item: IFinancialInstitutionConnection) => {
    LDFeatureForUserEnabled(window.Docyt.Common.Constants.QUILTT_INTEGRATION, String(window.Docyt.currentAdvisor.id)).then((isQuiltEnabled) => {
      if (isQuiltEnabled) {
        reconnectToAggregator({ id: item.id, aggregator: item.aggregator });
      } else {
        reconnectToAggregator({ id: item.id, aggregator: 'plaid' });
      }
    });
  }, [reconnectToAggregator]);

  return (
    <>
      <MutationStatus mutation={ generateLinkMutation } />
      <MutationStatus mutation={ update } successMessage="Financial Institution Connection was reconnected successfully" />
      <Modal
        dialogClassName="connect-financial-institution-modal"
        region="second-modal-region"
        show={ show }
        title="Connect Financial Institution"
      >
        <Modal.Body>
          <Content
            financialInstitutionConnections={ financialInstitutionConnections }
            onConnectNewFinancialInstitution={ onConnectNewFinancialInstitution }
            onSelectFinancialInstitution={ handleSelectFinancialInstitution }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsColor="blue"
            title="Close"
            onClick={ onCancel }
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateFinancialInstitutionModal;
