import React, { useCallback } from 'react';

import { useUpdateFinancialInstitutionConnection } from '@src/hooks/queries/financial_institution_connection';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';
import { LDFeatureForUserEnabled } from '@src/utils/config';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { ReconnectFinancialInstitutionIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { IConnectNewFinancialInstitutionData } from '../../connect_financial_institution/schema';
import { useConnectAggregatorAccount } from '../../hooks';

interface IReconnectActionProps {
  financialInstitutionConnection: IFinancialInstitutionConnection,
}

const ReconnectAction = ({
  financialInstitutionConnection,
}: IReconnectActionProps): JSX.Element | null => {
  const update = useUpdateFinancialInstitutionConnection();
  const { mutate: updateFinancialInstitution } = update;

  const connectNewFinancialInstitution = useCallback((
    data: IConnectNewFinancialInstitutionData,
  ) => {
    updateFinancialInstitution({
      id:                             financialInstitutionConnection.id,
      financialInstitutionConnection: data,
    });
  }, [updateFinancialInstitution, financialInstitutionConnection.id]);

  const {
    reconnectToAggregator,
    generateLinkMutation,
  } = useConnectAggregatorAccount({ onAggregatorConnected: connectNewFinancialInstitution });

  const handleClick = useCallback(() => {
    LDFeatureForUserEnabled(window.Docyt.Common.Constants.QUILTT_INTEGRATION, String(window.Docyt.currentAdvisor.id)).then((isQuiltEnabled) => {
      if (isQuiltEnabled) {
        reconnectToAggregator({ id: financialInstitutionConnection.id, aggregator: financialInstitutionConnection.aggregator });
      } else {
        reconnectToAggregator({ id: financialInstitutionConnection.id, aggregator: 'plaid' });
      }
    });
  }, [reconnectToAggregator, financialInstitutionConnection]);
  return (
    <>
      <MutationStatus mutation={ generateLinkMutation } />
      <MutationStatus mutation={ update } successMessage="Reconnected successfully" />
      <ActionsDropdown.Action
        icon={ <ReconnectFinancialInstitutionIcon fontSize={ 18 } /> }
        name="Reconnect"
        onClick={ handleClick }
      />
    </>
  );
};

export default ReconnectAction;
