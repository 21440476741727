import React, { ChangeEvent, useCallback, useState } from 'react';

import { useRemoveSpreadsheet, useUploadSpreadsheet } from '@src/hooks/queries/self_onboarding_invitations';
import { getDownloadSpreadSheet } from '@src/requests/self_onboarding_invitations';
import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';
import { createAndDownloadBlobFile } from '@src/utils/download_file';

import { Button } from '@src/components/ui_v2/buttons';
import { AngleDownIcon, AngleUpIcon } from '@src/components/utils/fa_icons';
import { CheckGreenIcon, SwapDownloadIcon, SwapUploadIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import styles from './styles.module.scss';

interface IBusinessDetailsProps {
  invitation: ISelfOnboardingInvitation,
  onFileUploaded: (value: boolean, parsedBusinessNames: string[]) => void,
}

const BusinessDetails = ({
  invitation,
  onFileUploaded,
}: IBusinessDetailsProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(true);
  const [uploadedFileName, setUploadedFileName] = useState<
    string | undefined>(invitation.onboardingFileOriginalName);

  const upload = useUploadSpreadsheet();
  const { mutate: uploadSpreadsheet } = upload;

  const remove = useRemoveSpreadsheet();
  const { mutate: removeSpreadsheet } = remove;

  const handleFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      uploadSpreadsheet(
        { id: invitation.id, file },
        { onSuccess: (res) => {
          setUploadedFileName(file.name);
          onFileUploaded(true, res.parsedBusinessNames);
        } },
      );
    }
  };

  const handleDownloadSpreadSheet = useCallback(() => {
    getDownloadSpreadSheet().then((res) => {
      // eslint-disable-next-line no-undef
      createAndDownloadBlobFile(AWS.util.base64.decode(res.data), res.fileName);
    });
  }, []);

  const handleRemoveSpreadSheet = useCallback(() => {
    removeSpreadsheet(
      { id: invitation.id },
      { onSuccess: () => {
        setUploadedFileName(undefined);
        onFileUploaded(false, []);
      } },
    );
  }, [invitation.id, onFileUploaded, removeSpreadsheet]);

  return (
    <>
      <MutationStatus mutation={ upload } successMessage="File uploaded successfully" />
      <MutationStatus mutation={ remove } successMessage="File removed successfully" />
      <div className={ styles['tasks-view'] }>
        <div className={ styles['tasks-view-header-wrap'] }>
          <div className={ styles['tasks-view-header'] }>
            <h2>1. Business Details</h2>
            { uploadedFileName && <CheckGreenIcon fontSize={ 24 } /> }
          </div>
          {
            showDetails ? (
              <AngleDownIcon className={ styles['angle-button'] } onClick={ () => setShowDetails(false) } />
            ) : (
              <AngleUpIcon className={ styles['angle-button'] } onClick={ () => setShowDetails(true) } />
            )
          }
        </div>
        { showDetails && (
          <>
            <span className="font-12 in-grey-1050 m-b-10">
              Download the below sample spreadsheet file and fill
              { ' ' }
              in your relevant business(es) details, then upload it.
            </span>
            <div className="display-flex justify-content-between">
              <Button
                className={ styles['download-spreadsheet-button'] }
                variant="link"
                onClick={ handleDownloadSpreadSheet }
              >
                <SwapDownloadIcon fontSize={ 16 } />
                <span className={ styles['download-spreadsheet-text'] }>Download spreadsheet</span>
              </Button>
              { uploadedFileName ? (
                <div className={ styles['uploaded-file-wrap'] }>
                  <span className={ styles['uploaded-file-name'] }>
                    { uploadedFileName }
                  </span>
                  <Button
                    className={ styles['remove-spreadsheet-button'] }
                    variant="link"
                    onClick={ handleRemoveSpreadSheet }
                  >
                    <span>&times;</span>
                  </Button>
                </div>
              ) : (
                <label className={ styles['upload-button'] } htmlFor="fileInput">
                  <input
                    accept=".xlsx"
                    className={ styles['file-input'] }
                    id="fileInput"
                    type="file"
                    onChange={ handleFileSelected }
                  />
                  <SwapUploadIcon fontSize={ 16 } mr={ 5 } />
                  Upload
                </label>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BusinessDetails;
