import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

interface IResultModalProps extends IUseModalProps {
  totalCount: number;
  errors: string[];
}

const ResultModal = ({
  totalCount,
  errors,
  isOpen,
  onCancel,
}: IResultModalProps): JSX.Element => {
  const failedCount = errors.length;
  const successCount = totalCount - errors.length;
  if (failedCount > 1) {
    errors.sort((a, b) => {
      const getRowNumber = (message: string) => {
        if (message) {
          const match = message.match(/Row (\d+) - /);
          return match ? parseInt(match[1], 10) : Infinity;
        }
        return -Infinity;
      };
      const rowNumberA = getRowNumber(a);
      const rowNumberB = getRowNumber(b);
      return rowNumberA - rowNumberB;
    });
  }

  return (
    <Modal
      show={ isOpen }
      title="Add Chart of Account"
    >
      <Modal.Body>
        <div>
          {successCount}
          {' '}
          chart of account(s) were updated successfully

          {
            failedCount > 0 && (
            <>
              <p>
                {failedCount}
                {' '}
                chart of account(s) has failed with the following errors:
              </p>
              <ul>
                { errors.map((i) => <li key={ i }>{i}</li>)}
              </ul>
            </>
            )
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-link modal-link-btn"
          fontSize={ 16 }
          title="OK"
          onClick={ onCancel }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useResultModal = makeUseModal(ResultModal);

export default useResultModal;
