import { useCallback, useEffect, useState } from 'react';

import toastr from '@lib/toastr';
import { useGeneratePlaidItemLinkToken, useRetrievePlaidItemLinkToken } from '@src/hooks/queries/plaid_items';
import usePlaidService from '@src/hooks/usePlaid';
import useQuiltt from '@src/hooks/useQuiltt';
import { IPlaidMetadata, IQuilltMeta } from '@src/types/financial_institution_connection';
import { showLinkPlaidWindow } from '@src/utils/plaid';

import { IConnectNewFinancialInstitutionData } from './connect_financial_institution/schema';

// this is a hard code flag for this specific component which will decide whether plaid should open in same window or it should open in separate windoe
const OPEN_PLAID_IN_SAME_WINDOW = false;

type TAggregatorsType = 'plaid' | 'quiltt'

interface IUseConnectPlaidAccountParams {
  onAggregatorConnected: (data: IConnectNewFinancialInstitutionData) => void,
}

const useConnectAggregatorAccount = ({
  onAggregatorConnected,
}: IUseConnectPlaidAccountParams) => {
  const generateLink = useGeneratePlaidItemLinkToken();
  const retrieveLink = useRetrievePlaidItemLinkToken();

  const [accountData, setAccountData] = useState<
    IConnectNewFinancialInstitutionData | null>(null);

  const handleAggregatorAccountConnected = useCallback((
    metadata: IPlaidMetadata | IQuilltMeta,
  ) => {
    if (!accountData) return;

    // prepare the request data based on aggregator
    const aggregatorMetadata = (() => {
      if (metadata.type === 'quiltt') {
        return { quilttConnectionId: metadata.connectionId, quilttConnectorId: metadata.connectorId, quilttProfileId: metadata.profileId };
      }
      return { plaidPublicToken: metadata.publicToken, plaidAccountId: metadata.account_id };
    })();

    onAggregatorConnected({
      ...accountData,
      ...aggregatorMetadata,
    });

    setAccountData(null);
  }, [accountData, onAggregatorConnected]);

  const { open, ready: isPlaidReady, setLinkToken } = usePlaidService({
    // eslint-disable-next-line camelcase
    handleOnSuccess: (public_token, metadata) => {
      // eslint-disable-next-line camelcase
      const data = { ...metadata, publicToken: public_token };
      handleAggregatorAccountConnected(data);
    },
    handleOnExit: (error) => {
      if (error) {
        toastr.error(error, 'Error');
      }
    },
  });

  /**
   * This function used to load the Plaid UI also it will decide whether UI should load on current window or new window
   * @param linkToken palid link token
   */
  const loadPliadUI = useCallback((linkToken: string) => {
    // if this condition false plaid will be open in a saperate window
    if (OPEN_PLAID_IN_SAME_WINDOW) {
      // once linktoken set to the state it will trigger the useEffect and load the plaid UI in same window
      setLinkToken(linkToken);
    } else {
      showLinkPlaidWindow(linkToken);
    }
  }, [setLinkToken]);

  const { open: openQuiltt, setReConnectInstitution } = useQuiltt({
    handleOnSuccessCallback: (metadata) => {
      handleAggregatorAccountConnected(metadata);
    },
  });

  // Handle effect of updating the token for Plaid into state and open the Plaid window when it's ready
  useEffect(() => {
    if (isPlaidReady) {
      open();
    }
  }, [isPlaidReady, open]);

  useEffect(() => {
    // eslint-disable-next-line camelcase
    window.Docyt.vent.on('plaid:account:connected', (public_token: string, metadata: IPlaidMetadata) => {
      onAggregatorConnected({
        ...accountData,
        // eslint-disable-next-line camelcase
        plaidPublicToken: public_token,
        plaidAccountId:   metadata.account_id,
      });
    });

    return () => {
      window.Docyt.vent.off('plaid:account:connected');
    };
  }, [accountData, handleAggregatorAccountConnected, onAggregatorConnected]);

  const { mutate: generate } = generateLink;
  const handleConnectToAggregator = useCallback((data: IConnectNewFinancialInstitutionData, aggregator?: TAggregatorsType) => {
    setAccountData(() => ({ ...data, aggregator }));
    switch (aggregator) {
      case 'quiltt':
        openQuiltt();
        break;
      case 'plaid':
      default:
        generate({
          product: 'transactions',
        }, {
          onSuccess: (financialInsitutionConnection) => {
            loadPliadUI(financialInsitutionConnection.linkToken);
          },
        });
        break;
    }
  }, [generate, loadPliadUI, openQuiltt]);

  const { mutate: retrieve } = retrieveLink;
  const handleReconnectToAggregator = useCallback((data: IConnectNewFinancialInstitutionData) => {
    setAccountData(data);
    switch (data.aggregator) {
      case 'quiltt':
        // this connection id will be dynamic once api is get ready
        setReConnectInstitution({
          connectionId: 'conn_12xCa5vH7fJcByFBgAReMd',
        });
        break;
      case 'plaid':
      default:
        window.Docyt.vent.trigger('show:spinner');
        retrieve({
          financialInstitutionConnectionId: data.id,
        }, {
          onSuccess: (financialInsitutionConnection) => {
            window.Docyt.vent.trigger('hide:spinner');
            loadPliadUI(financialInsitutionConnection.linkToken);
          },
        });
        break;
    }
  }, [retrieve, loadPliadUI, setReConnectInstitution]);

  return {
    connectAggregator:     handleConnectToAggregator,
    reconnectToAggregator: handleReconnectToAggregator,
    generateLinkMutation:  generateLink,
  };
};

export {
  useConnectAggregatorAccount,
};
