/* eslint-disable eqeqeq */
import React, { useState } from 'react';

import { accountsPayableSettingsQueuePath, achTransactionsPath, approvalAuditControlsPath, businessSettingsPath, checkRegisterPath, docytBusinessNetworkPath, expenseReportsAddExpensePath, expenseReportsEmployeeSubmissionsPath, expenseReportsReimbursementCategoriesPath, expenseReportsServicePath, expenseReportsSettingsPath, invoiceQueuePath, myVendorsPath, paymentMethodSetupPath, paymentRulesQueuePath, receiptListPath, settlementItemsPath, vendors1099ReportsPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';
import { LDFeatureValueForBusiness, LDFeatureForBusinessEnabled, isFeatureEnabled } from '@src/utils/config';

import AddCircleIcon from '@src/components/utils/icomoon/add_circle';
import OpenInNewIcon from '@src/components/utils/icomoon/open_in_new';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';
import ServiceSubMenu from '../module_header_sub_menu';

import styles from '../../navigation_styles.module.scss';

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  info: IBusinessServicesInfo,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

const ExpenseItem = ({
  businessId,
  info,
  activeItem,
  focusToMyClient,
  isGlobalNavigationEnabled
}: IServiceMenuProps) => {
  const [achTransactionsEnabled, setAchTransactionsEnabled] =
    useState<string | boolean | undefined>(
      LDFeatureValueForBusiness(
        window.Docyt.Common.Constants.ACH_TRANSACTIONS_FLAG,
        String(businessId),
      ),
    );

  if (achTransactionsEnabled === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.ACH_TRANSACTIONS_FLAG,
      String(businessId),
    ).then((res) => {
      setAchTransactionsEnabled(res);
    });
  }

  const canVisibleApService = info.isBusinessAdmin || info.canAccessApService;
  const canVisibleVendorService = info.isBusinessAdmin || info.canAccessVendorService;
  const canVisibleExpenseReportService = info.isBusinessAdmin || info.canAccessExpenseReportService;
  const canVisibleRpService = info.isBusinessAdmin || info.canAccessRpService;

  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.EXPENSE }
      focusToMyClient={ focusToMyClient }
      isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
      title={ window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.EXPENSE }
    >
      <li className='p-t-8'>
        <span className={ `m-l-18 m-b-8 ${styles['dropdown-item-title']}` }>
          { window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.EXPENSE.toUpperCase() }
        </span>
      </li>
      {canVisibleApService && (
        <ServiceSubMenu
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.BILL_PAY }
        >
          <ServiceItem
            hasBorderBottom
            serviceUrl={
              info.apServiceReady
                ? invoiceQueuePath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Invoice Queue"
          />
          <ServiceItem
            serviceUrl={
              info.apServiceReady
                ? checkRegisterPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Check Register"
          />
          <ServiceItem
            isHidden={ !achTransactionsEnabled }
            serviceUrl={
              info.apServiceReady
                ? achTransactionsPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="ACH Register"
          />
          <ServiceItem
            hasBorderBottom
            serviceUrl={
              info.apServiceReady
                ? settlementItemsPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Inter-entity Settlements"
          />
          <ServiceItem
            serviceUrl={ approvalAuditControlsPath(businessId) }
            title="Approval & Audit Controls"
          />
          <ServiceItem
            serviceUrl={
              info.apServiceReady
                ? paymentRulesQueuePath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Payment Rules"
          />
          <ServiceItem
            serviceUrl={ paymentMethodSetupPath(businessId) }
            title="Payment Method Setup"
          />
          <ServiceItem
            serviceUrl={
              info.apServiceReady
                ? accountsPayableSettingsQueuePath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Settings"
          />
        </ServiceSubMenu>
      )}
      {(isFeatureEnabled('vendor_service') && canVisibleVendorService) && (
        <ServiceSubMenu
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.VENDOR_SERVICE }
        >
          <ServiceItem
            isHidden={ !isFeatureEnabled('vendor_service') }
            serviceUrl={ myVendorsPath(businessId) }
            title="All Vendors"
          />
          {canVisibleVendorService && (
            <>
              <ServiceItem
                serviceUrl={ vendors1099ReportsPath(businessId) }
                title="1099 Reports"
              />
              <ServiceItem
                serviceUrl={ docytBusinessNetworkPath(businessId) }
                title="Docyt Business Network"
              />
            </>
          )}
        </ServiceSubMenu>
      )}
      <ServiceSubMenu
        title={ window.Docyt.Common.Constants.SERVICE_NAMES.EXPENSE_REPORT_SERVICE }
      >
        {canVisibleExpenseReportService && (
          <>
            <ServiceItem
              openInNewTab
              isHidden={ !isFeatureEnabled('expense_ach') }
              serviceUrl={ expenseReportsAddExpensePath(businessId) }
              title={ (
                <div className={ `${styles['icon-with-text']}` }>
                  <span>
                    <AddCircleIcon fontSize={ 18 } />
                    <span className={ `${styles['sub-menu-text']}` }>Add Expense</span>
                  </span>
                  <span className={ `${styles['open-in-new-tab-box']}` }>
                    <OpenInNewIcon fontSize={ 18 } />
                  </span>
                </div>
              ) }
            />
          
            <ServiceItem
              hasBorderBottom
              isHidden={ !isFeatureEnabled('expense_ach') }
              serviceUrl={ expenseReportsServicePath(businessId) }
              title="My Reimbursements"
            />
          </>
        )}
        <ServiceItem
          serviceUrl={ expenseReportsEmployeeSubmissionsPath(businessId) }
          title="Employee Submissions"
        />
        <ServiceItem
          hasBorderBottom
          serviceUrl={ expenseReportsReimbursementCategoriesPath(businessId) }
          title="Reimbursement Categories"
        />
        <ServiceItem
          serviceUrl={ expenseReportsSettingsPath(businessId) }
          title="Settings"
        />
      </ServiceSubMenu>
      <ServiceSubMenu
        title={ window.Docyt.Common.Constants.SERVICE_NAMES.CORPORATE_CARDS }
      >
        {canVisibleRpService && (
          <ServiceItem
            serviceUrl={
              info.rpServiceReady
                ? receiptListPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="All Receipts"
          />
        )}
        <ServiceItem
          serviceUrl=""
          title="Missing Receipts"
        />
        <ServiceItem
          serviceUrl=""
          title="Settings"
        />
      </ServiceSubMenu>
    </ModuleHeaderMenu>
  );
};

export default ExpenseItem;
