import React from 'react';

import { TID, TMongoID } from '@src/types/common';
import { TReportCategory } from '@src/types/report_service/report';

import BusinessProvider from '@src/components/providers/business_provider';
import ReportsServiceProvider from '@src/components/providers/reports_service_provider';
import Root from '@src/components/root';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import PageContainer from '@src/components/ui_v2/layout/page_container';

import Router from './router';
import Sidemenu from './sidemenu';

interface IBusinessReportsPageProps {
  businessId: TID,
  category: TReportCategory,
  slug: string,
  page?: string,
  showSideMenu?: boolean,
  budgetId: TMongoID,
  itemIdentifier?: string,
  type?: string
}

const BusinessReportsPage = ({
  businessId,
  category,
  slug,
  page,
  showSideMenu = true,
  budgetId,
  itemIdentifier,
  type
}: IBusinessReportsPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ReportsServiceProvider businessId={ businessId }>
          <PageContainer sidemenu={ showSideMenu && <Sidemenu category={ category } /> }>
            <DetailsRegion>
              <Router
                budgetId={ budgetId }
                category={ category }
                itemIdentifier={ itemIdentifier }
                page={ page }
                slug={ slug }
                type={ type }
              />
            </DetailsRegion>
          </PageContainer>
        </ReportsServiceProvider>
      </BusinessProvider>
    </Root>
  );
};

export default BusinessReportsPage;
