module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="title-region"></div>\n<div id="qbo-document-page-region"></div>\n<div id="imported-from-qbo-notify-region"></div>\n<div id="transaction-payment-account-region"></div>\n<div id="client-document-fields-region" class="client-document-fields-region"></div>\n<div id="view-transaction-context-region"></div>\n<div id="check-document-memo-region" class=""></div>\n<div id="client-document-payment-account-region" class=""></div>\n<div id="check-document-download-region" class=""></div>\n<div id="client-document-payment-date-region" class=""></div>\n<div id="transaction-document-fields-region" class="transaction-document-fields-region"></div>\n<div id="transaction-detail-region" class="transaction-detail-region"></div>\n<div id="matched-document-region" class="matched-document-region"></div>\n<div id="journal-entry-document-region" class="journal-entry-document-region m-t-10"></div>\n<div id="related-transaction-document-region" class="related-transaction-document-region m-t-10"></div>\n<div id="client-document-businesses-region" class=""></div>\n<div id="client-document-address-region" class=""></div>\n<div id="document-customer-region" class=""></div>\n<div id="client-document-note-region" class=""></div>\n<div id="client-document-tax-info-region" class=""></div>\n<div id="adjustment-entry-region" class="adjustment-entry-region"></div>\n<div id="document-action-region" class="document-action-region"></div>\n<div id="accrual-entries-region" class="accrual-entries-region"></div>\n<div id="possible-match-region" class="possible-match-region"></div>\n<div id="related-documents-region" class=""></div>\n<div id="check-status-region"></div>\n<div id="document-past-payments-region" class=""></div>\n<div id="matched-items-region" class="matched-items-region"></div>\n<div id="chat-region" class="chat-region"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}