import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

import Transactions from './transactions';

interface IBankingAccountsTransactionsPageProps {
  businessId: TID,
  isAdmin: boolean,
  isTrash: boolean,
}

const BankingAccountsTransactionsPage = ({
  businessId,
  isAdmin,
  isTrash,
}: IBankingAccountsTransactionsPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <Transactions businessId={ businessId } isAdmin={ isAdmin } isTrash={ isTrash } />
      </BusinessProvider>
    </Root>
  );
};

export default BankingAccountsTransactionsPage;
