import React, { useCallback, useState } from 'react';

import { SubmitHandler } from 'react-hook-form';

import { Button } from '@src/components/ui_v2/buttons';
import { AngleDownIcon, AngleUpIcon } from '@src/components/utils/fa_icons';
import { PlusIcon } from '@src/components/utils/icomoon';

import Form from './form';
import { IFormValues } from './schema';

import styles from '../styles.module.scss';

interface IKYCDocumentsProps {
  formId: string;
  parsedBusinessNames: string[],
  onSubmit: SubmitHandler<IFormValues>,
}

const KYCDocuments = ({
  formId,
  parsedBusinessNames,
  onSubmit,
}: IKYCDocumentsProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(true);
  const [addBusinessEvented, setAddBusinessEvented] = useState<boolean>(false);

  const handleAddBusiness = useCallback(() => {
    setAddBusinessEvented(true);
    setTimeout(() => setAddBusinessEvented(false), 1000);
  }, []);
  return (
    <div className={ styles['tasks-view'] }>
      <div className={ styles['tasks-view-header-wrap'] }>
        <div className={ styles['tasks-view-header'] }>
          <h2>2. &apos;Know Your Customer&apos; Documents</h2>
        </div>
        {
          showDetails ? (
            <AngleDownIcon className={ styles['angle-button'] } onClick={ () => setShowDetails(false) } />
          ) : (
            <AngleUpIcon className={ styles['angle-button'] } onClick={ () => setShowDetails(true) } />
          )
        }
      </div>
      { showDetails && (
        <div className={ styles['tasks-view-body'] }>
          <span className="font-12 in-grey-1050 m-b-10">
            Take a moment to upload the
            { ' ' }
            <b>EIN Letter, Business License</b>
            { ' ' }
            and
            { ' ' }
            <b>Authorized Signatory Driver&apos;s License</b>
            { ' ' }
            for all the business you have mentioned in the business details spreadsheet.
            { ' ' }
            Skip this step if you don’t need to pay the vendors through Docyt check or Docyt ACH.
          </span>
          <Form
            addBusinessEvented={ addBusinessEvented }
            formId={ formId }
            parsedBusinessNames={ parsedBusinessNames }
            onSubmit={ onSubmit }
          />
        </div>
      )}
      { showDetails && (
        <Button
          className={ styles['add-business-button'] }
          variant="link"
          onClick={ handleAddBusiness }
        >
          <div className={ styles['plus-icon'] }><PlusIcon fontSize={ 10 } fontVariant="bold" /></div>
          <span data-color="$purple-1000">Add Business</span>
        </Button>
      ) }
    </div>
  );
};

export default KYCDocuments;
