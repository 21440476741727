import React from 'react';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { ExportAsExcelIcon } from '@src/components/utils/icomoon';

import { useMultiEntityDownloadConsolidatedReportModal } from '../modals/download_consolidated_report';

import styles from '../styles.module.scss';

const PortfolioListHeaderAction = ({
  reportIds,
  onCreateExportData,
}: {
  reportIds: string[],
  onCreateExportData: (value: boolean) => void,
}) => {
  const modal = useMultiEntityDownloadConsolidatedReportModal();

  return (
    <>
      <ActionsDropdown className={ `${styles['actions-dropdown']} m-r-15 m-t-5` }>
        <ActionsDropdown.Action
          className={ styles['dropdown-item'] }
          icon={ <ExportAsExcelIcon fontSize={ 18 } /> }
          title="Download Consolidated Report"
          onSelect={ () => modal.open() }
        />
      </ActionsDropdown>
      <modal.Component
        { ...modal.props }
        reportIds={ reportIds }
        onCreateExportData={ onCreateExportData }
      />
    </>
  );
};

export default React.memo(PortfolioListHeaderAction);
