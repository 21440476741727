import React, { useState } from 'react';

import { TID } from '@src/types/common';
import { IReport, TReportCategory } from '@src/types/report_service/report';
import { LDFeatureForBusinessEnabled, LDFeatureValueForBusiness } from '@src/utils/config';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import EditMappingAction from './edit_mapping_action';
import ExportReportAsExcelAction from './export_report_as_excel_action';
import ExportReportAsPdfAction from './export_report_as_pdf_action';
import RenameReportAction from './rename_report_action';

interface IReportDetailHeaderActionsProps {
  businessId: TID,
  report: IReport,
  category?: TReportCategory,
}

const ReportDetailHeaderActions = ({
  businessId,
  report,
  category,
}: IReportDetailHeaderActionsProps): JSX.Element => {
  const [exportAsPdfEnabled, setExportAsPdfEnabled] = useState<string | boolean | undefined>(
    LDFeatureValueForBusiness(
      window.Docyt.Common.Constants.EXPORT_REPORT_AS_PDF_FLAG,
      String(businessId),
    ),
  );

  if (exportAsPdfEnabled === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.EXPORT_REPORT_AS_PDF_FLAG,
      String(businessId),
    ).then((res) => {
      setExportAsPdfEnabled(res);
    });
  }

  return (
    <ActionsDropdown className="m-l-auto m-r-15 m-t-5">
      <RenameReportAction report={ report } />
      <EditMappingAction category={ category } report={ report } />
      <ExportReportAsExcelAction
        businessId={ businessId }
        category={ category }
        report={ report }
      />

      { exportAsPdfEnabled && (
        <ExportReportAsPdfAction report={ report } />
      )}
    </ActionsDropdown>
  );
};

export default ReportDetailHeaderActions;
