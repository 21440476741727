import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import { useTSDCollection } from './hooks';
import List from './list';

import styles from './styles.module.scss';

const AllTransactions = () => {
  const business = useBusinessContext();

  const tsdCollection = useTSDCollection({ businessId: business.id });

  return (
    <DetailsRegion>
      <DetailsRegion.Header
        title="All Transactions"
      />
      <div className={ styles['tables-container'] }>
        <List collection={ tsdCollection } />
      </div>
    </DetailsRegion>
  );
};

export default React.memo(AllTransactions);
