/* eslint-disable eqeqeq */
import React, { useMemo } from 'react';

import { useGetMetricsTotalCount } from '@src/hooks/queries/metrics';
import {
  budgetsPath,
  departmentReportsPath,
  expenseReportsPath,
  laborMetricsPath,
  laborReportsPath,
  managementReportsPath,
  metricsCSVImportPath,
  metricsPath,
  revenueReportsPath,
} from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';
import ServiceSubMenu from '../module_header_sub_menu';
import { reportServiceUrl } from '../utils';
import RegularReportServiceItems from './regular_report_service_item';

interface IServiceMenuProps {
  businessId: TID,
  info: IBusinessServicesInfo,
  activeItem?: string,
  focusToMyClient: boolean
}

const BusinessReportItem = ({
  businessId,
  info,
  activeItem,
  focusToMyClient
}: IServiceMenuProps) => {
  const featureFlagforLabor = useMemo(
    () =>
      window.configData.launch_darkly_enabled_global_features.includes(
        window.Docyt.Common.Constants.BUSINESS_LABOR_REPORT_V2_ENABLED
      ),
    []
  );
  
  const { data: metricsTotalCount } = useGetMetricsTotalCount({
    businessId,
    standardIndustryId: info.standardCategoryId || -1,
    hasMetricsServiceRole: info.hasMetricsServiceRole
  });

  const canVisibleReportService = info.isBusinessAdmin || info.canAccessReportService;
  const canVisibleMetricsService = info.isBusinessAdmin || info.hasMetricsServiceRole;

  if (!canVisibleReportService && !canVisibleMetricsService) return null;

  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.BUSINESS_REPORTS }
      focusToMyClient={ focusToMyClient }
      title={ window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.BUSINESS_REPORTS }
    >
      {
        canVisibleReportService && (
          <ServiceSubMenu
            serviceUrl={ reportServiceUrl(info, businessId) }
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.REPORT_SERVICE }
          >
            <RegularReportServiceItems
              businessId={ businessId }
              businessInfo={ info }
            />
            <ServiceItem
              isHidden={
                info.standardCategoryId
                != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.HOSPITALITY_ID
                && info.standardCategoryId
                != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.UPS_ID
                && info.standardCategoryId
                != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.QSR_ID
              }
              serviceUrl={ revenueReportsPath(businessId) }
              title="Revenue"
            />
            <ServiceItem
              serviceUrl={ expenseReportsPath(businessId) }
              title="Expense"
            />
            <ServiceItem
              isHidden={
                info.standardCategoryId != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.HOSPITALITY_ID
                || !featureFlagforLabor
              }
              serviceUrl={ laborReportsPath(businessId) }
              title="Labor"
            />
            <ServiceItem
              isHidden={
                info.standardCategoryId
                != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.HOSPITALITY_ID
                && info.standardCategoryId
                != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.UPS_ID
                && info.standardCategoryId
                != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.QSR_ID
                && info.standardCategoryId
                != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.SAAS_ID
                && info.standardCategoryId
                != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.OTHER_ID
              }
              serviceUrl={ managementReportsPath(businessId) }
              title="Management Reports"
            />
            <ServiceItem
              launchDarklyFeature="budgets-enabled"
              serviceUrl={ budgetsPath(businessId) }
              title="Budget and Forecast"
            />
            <ServiceItem
              isHidden={
                info.standardCategoryId
                != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.HOSPITALITY_ID
                && info.standardCategoryId
                != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.SAAS_ID
              }
              serviceUrl={ departmentReportsPath(businessId) }
              title="Departmental Reports"
            />
          </ServiceSubMenu>
        )
      }
      {
        canVisibleMetricsService && (
          <ServiceSubMenu
            isHidden={ metricsTotalCount?.totalCount == 0 }
            launchDarklyFeature="metrics-service"
            serviceUrl={ metricsPath(businessId) }
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.METRICS_SERVICE }
          >
            <ServiceItem
              serviceUrl={ metricsPath(businessId) }
              title="Business Metric"
            />
            <ServiceItem
              serviceUrl={ metricsPath(businessId) }
              title="Industry Metrics"
            />
            <ServiceItem
              isHidden
              serviceUrl={ laborMetricsPath(businessId) }
              title="Labor Metrics"
            />
            <ServiceItem
              isHidden
              serviceUrl={ metricsCSVImportPath(businessId) }
              title="CSV Import"
            />
          </ServiceSubMenu>
        )
      }
    </ModuleHeaderMenu>
  );
};

export default BusinessReportItem;
