import React from 'react';

import BusinessProvider from '@src/components/providers/business_provider';
import MetricsServiceProvider from '@src/components/providers/metrics_service_provider';
import Root from '@src/components/root';

import BusinessMetricRouter from './business_metric_value_details';
import Details from './details';
import Sidemenu from './sidemenu';

interface IMetricsServiceDetailsPageProps {
  businessId: number,
  isIndustryMetrics: boolean,
  businessMetricId?: number,
}

const MetricsServiceDetailsPage = ({
  businessId,
  isIndustryMetrics,
  businessMetricId,
}: IMetricsServiceDetailsPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <MetricsServiceProvider businessId={ businessId }>
          <div id="sidemenu-region">
            <Sidemenu />
          </div>
          <div id="detail-region">
            {
              businessMetricId
                ? (
                  <BusinessMetricRouter />
                ) : (
                  <Details
                    businessId={ businessId }
                    isIndustryMetrics={ isIndustryMetrics }
                  />
                )
            }
          </div>
        </MetricsServiceProvider>
      </BusinessProvider>
    </Root>
  );
};

export default MetricsServiceDetailsPage;
