import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useInboxServiceContext } from '@src/hooks/contexts/inbox_service_context';

import ServiceMenu from '@src/components/common/service_menu';
import ServiceSidebar from '@src/components/ui/service_sidebar';
import {
  BusinessInboxIcon,
  BusinessInboxTrashIcon,
} from '@src/components/utils/icomoon';

const BusinessInboxSidemenu = () => {
  const business = useBusinessContext();
  const service = useInboxServiceContext();

  const homeURL = `/businesses/${business.id}/business_inbox`;

  return (
    <ServiceSidebar
      serviceMenu={ (
        <ServiceMenu
          service={ service }
          serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.MAILROOM_SERVICE }
        />
      ) }
    >
      <ServiceSidebar.Item
        badge={ service.totalInboxCount }
        href={ homeURL }
        icon={ <BusinessInboxIcon /> }
        title="Inbox"
      />
      <ServiceSidebar.Item
        badge={ service.deletedInboxCount }
        href={ `${homeURL}/trash` }
        icon={ <BusinessInboxTrashIcon /> }
        title="Trash"
      />
    </ServiceSidebar>
  );
};

export default BusinessInboxSidemenu;
