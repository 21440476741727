import { IVendorsService } from '@src/types/services';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

const getVendorsServiceByBusinessId = (businessId: number): Promise<IVendorsService> => {
  return apiGet(
    '/api/v1/vendor_services/by_business_id',
    underscoreKeys({ businessId }),
  ).then((data) => camelizeKeys(data.vendor_service) as IVendorsService);
};

export {
  getVendorsServiceByBusinessId,
};
