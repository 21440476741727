import { useQuery } from 'react-query';

import {
  getVendorsServiceByBusinessId,
} from '@src/requests/vendors_service';
import { IVendorsService } from '@src/types/services';

const VENDORS_SERVICE_QUERY = 'vendor_service';

const useGetVendorsServiceByBusinessIdQuery = (businessId: number) => {
  return useQuery<IVendorsService, Error>(
    [VENDORS_SERVICE_QUERY, businessId],
    () => getVendorsServiceByBusinessId(businessId),
    {
      staleTime: Infinity,
      enabled:   !!businessId,
    },
  );
};

export {
  VENDORS_SERVICE_QUERY,
  useGetVendorsServiceByBusinessIdQuery,
};
