import React from 'react';

import { Label } from '@src/components/ui_v2/labels';

import Cell, { ITableCellProps } from './cell';

interface ITableLabelCellProps extends Omit<ITableCellProps, 'children' | 'title'> {
  title: React.ReactNode,
  variant?: React.ComponentPropsWithoutRef<typeof Label>['variant'],
}

const TableLabelCell = ({
  title,
  variant,
  ...cellProps
}: ITableLabelCellProps) => {
  return (
    <Cell { ...cellProps }>
      <Label variant={ variant }>
        { title }
      </Label>
    </Cell>
  );
};

export default TableLabelCell;
