import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import Action from './open_items_sidebar_action';

interface IOpenItemsSidebarProps {
  businessId: TID,
}

const OpenItemsSidebar = ({
  businessId,
}: IOpenItemsSidebarProps): JSX.Element => {
  return (
    <Root>
      <Action
        businessId={ businessId }
      />
    </Root>
  );
};

export default OpenItemsSidebar;
