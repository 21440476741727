import React, { useCallback, useMemo } from 'react';

import { ISorting } from '@src/types/sorting';

import Table, { ITableProps } from '@src/components/ui_v2/table';
import TableSection from '@src/components/ui_v2/table_section';
import { useInitItemsSelector } from '@src/components/utils_v2/items_selector';

import Header from './header';
import { useCollectionColumnsState } from './hooks';
import Row, { ICollectionTableRowProps } from './row';
import Settings from './settings';
import { TColumn, TModel } from './types';

interface ICollectionTableProps<Model extends TModel, TSortColumn extends string = never> {
  bulkActions?: React.ComponentPropsWithoutRef<typeof Header>['bulkActions'],
  className?: string,
  children: React.ReactElement<TColumn<Model, TSortColumn>>
    | React.ReactElement<TColumn<Model, TSortColumn>>[],
  defaultColumnCount?: number,
  filter?: React.ComponentPropsWithoutRef<typeof TableSection.Panel>['filter'],
  height?: React.ComponentPropsWithoutRef<typeof TableSection.Panel>['height'],
  hideSectionBorder?: boolean,
  idField?: keyof Model,
  isRegionScroll?: boolean,
  /**
   * A function that returns `true` if the row is clickable.
   *
   * Pass this function if you want to have custom logic to check if a row is clickable or not.
   * If this function is not passed, then row will be clickable if `onRowClick` callback is passed.
   */
  isRowClickable?: ICollectionTableRowProps<Model, TSortColumn>['isRowClickable']
  isRowInactive?: ICollectionTableRowProps<Model, TSortColumn>['isRowInactive']
  isRowSelectable?: ICollectionTableRowProps<Model, TSortColumn>['isRowSelectable']
  noDataMessage?: React.ReactNode,
  footer?: React.ReactNode,
  query?: ITableProps['query'],
  records: Model[],
  showSelect?: boolean,
  showSettings?: boolean,
  sorting: ISorting<TSortColumn>,
  sticky?: boolean,
  onRowClick?: ICollectionTableRowProps<Model, TSortColumn>['onClick'],
  onCheckboxClick?: ICollectionTableRowProps<Model, TSortColumn>['onCheckboxClick'],
}

const CollectionTable = <Model extends TModel, TSortColumn extends string = never>({
  bulkActions,
  children,
  className,
  defaultColumnCount,
  filter,
  height,
  hideSectionBorder,
  idField = 'id',
  isRegionScroll,
  isRowClickable,
  isRowInactive,
  isRowSelectable,
  query,
  noDataMessage,
  footer,
  records,
  showSelect,
  showSettings = true,
  sorting,
  sticky,
  onRowClick,
  onCheckboxClick,
}: ICollectionTableProps<Model, TSortColumn>) => {
  const [columns, setColumns] = useCollectionColumnsState(children, undefined, defaultColumnCount);
  const ids = useMemo(() => {
    if (!showSelect) return [];

    return records.map((object) => object[idField]);
  }, [records, idField, showSelect]);

  useInitItemsSelector(ids);

  const handleToggleColumn = useCallback((column, checked) => {
    setColumns((oldColumns) => {
      return oldColumns.map((c) => {
        if (c.name !== column.name) return c;

        return {
          ...c,
          hidden: !checked,
        };
      });
    });
  }, [setColumns]);

  return (
    <TableSection.Panel
      className={ className }
      filter={ filter }
      height={ height }
      hideBorder={ hideSectionBorder }
      tableSettings={ showSettings && (
        <Settings
          columns={ columns }
          hasFilter={ Boolean(filter) }
          onToggleColumn={ handleToggleColumn }
        />
      ) }
    >
      <Table
        isRegionScroll={ isRegionScroll }
        query={ query }
        sticky={ sticky }
      >
        <Header<Model, TSortColumn>
          bulkActions={ bulkActions }
          columns={ columns }
          showSelect={ showSelect }
          sorting={ sorting }
        />

        <Table.Body>
          { records.map((object) => (
            <Row<Model, TSortColumn>
              key={ object[idField] }
              columns={ columns }
              idField={ idField }
              isRowClickable={ isRowClickable }
              isRowInactive={ isRowInactive }
              isRowSelectable={ isRowSelectable }
              object={ object }
              showSelect={ showSelect }
              onCheckboxClick={ onCheckboxClick }
              onClick={ onRowClick }
            />
          )) }
        </Table.Body>
      </Table>

      <TableSection.Footer>
        { footer }
      </TableSection.Footer>

      <TableSection.NoDataMessage
        message={ noDataMessage }
        query={ query }
        recordsLength={ records.length }
      />
    </TableSection.Panel>
  );
};

export default React.memo(CollectionTable) as typeof CollectionTable;
