import React from 'react';

import { businessDashboardPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { IDashboard } from '@src/types/dashboards/dashboards';

import { Button, AppLink } from '@src/components/ui_v2/buttons';
import Dropdown from '@src/components/ui_v2/dropdown';
import { CaretIcon } from '@src/components/utils/fa_icons';
import { PlusIcon } from '@src/components/utils/icomoon';

import { useCreateDashboardModal } from '../create_dashboard/create_dashboard_modal';

import styles from './styles.module.scss';

interface ISidemenuHeaderProps {
  dashboards: IDashboard[],
  currentDashboard: IDashboard,
  businessServiceInfo?: IBusinessServicesInfo,
}

const SidemenuHeader = ({
  dashboards,
  currentDashboard,
  businessServiceInfo,
}: ISidemenuHeaderProps): JSX.Element => {
  const businessId = currentDashboard.businessId;

  const createDashboard = useCreateDashboardModal();

  return (
    <div className={ styles['sidemenu-header-container'] }>
      <createDashboard.Component
        { ...createDashboard.props }
        businessId={ businessId }
        standardCategoryId={ businessServiceInfo?.standardCategoryId }
      />
      <Dropdown.Provider
        isFocusFirstMenuItem={ false }
      >
        <Dropdown.ToggleButton className={ styles['sidemenu-dropdown-toggle-button'] }>
          <div>{ currentDashboard?.name }</div>
          <CaretIcon ml={ 10 } variant="down" />
        </Dropdown.ToggleButton>
        <Dropdown.Menu>
          {
            dashboards.map((dashboard) => (
              <Dropdown.Item
                key={ dashboard.id }
                className={ styles['sidemenu-item'] }
                isSelected={ dashboard.id === currentDashboard.id }
              >
                <AppLink href={ businessDashboardPath(businessId, dashboard.id) }>
                  { dashboard.name }
                </AppLink>
              </Dropdown.Item>
            ))
          }
          {
            businessServiceInfo?.isBusinessAdmin && (
              <Dropdown.Item
                key="new-dashboard-item"
                className={ styles['sidemenu-item'] }
              >
                <Button
                  prefixIcon={ <PlusIcon fontSize={ 12 } /> }
                  variant="link"
                  onClick={ createDashboard.open }
                >
                  New Dashboard
                </Button>
              </Dropdown.Item>
            )
          }
        </Dropdown.Menu>
      </Dropdown.Provider>
    </div>
  );
};

export default SidemenuHeader;
