import React, { useCallback, useState } from 'react';

import { useBulkUploadKYCDocuments } from '@src/hooks/queries/self_onboarding_invitations';
import { selfOnboardingSuccessPath } from '@src/routes';
import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { Button } from '@src/components/ui/buttons';
import { ErrorNotification } from '@src/components/ui/notification';
import { SuccessIcon } from '@src/components/utils/icomoon';

import BusinessDetails from './business_details';
import KYCDocuments from './kyc_documents/kyc_documents_details';
import { IAddBusinessInput, IFormValues } from './kyc_documents/schema';

import styles from './styles.module.scss';

const formId = 'kyc_documents';

interface IRequestDocumentViewProps {
  invitation: ISelfOnboardingInvitation,
  isSuccess: boolean,
}

const RequestDocumentView = ({
  invitation,
  isSuccess,
}: IRequestDocumentViewProps) => {
  const [uploadedBusinessDetails, setUploadedBusinessDetails] = useState<boolean>(
    Boolean(invitation.onboardingFileOriginalName),
  );
  const [parsedBusinessNames, setParsedBusinessNames] = useState<string[]>(
    invitation.parsedBusinessNames,
  );
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const uploadKYCDocuments = useBulkUploadKYCDocuments();
  const bulkAction = useBulkActionModal({
    mutation: uploadKYCDocuments,
  });

  const { runMutation } = bulkAction;

  const handleSubmit = useCallback((value: IFormValues) => {
    if (value.newBusinesses.length === 0) {
      setErrorMsg('Please add at least 1 business.');
      return;
    }
    const params1 = value.newBusinesses.map((addedBusiness: IAddBusinessInput) => ({
      index:        addedBusiness.index,
      id:           invitation.id,
      businessName: addedBusiness.businessName,
      documentType: 'EIN_LETTER',
      file:         addedBusiness.einLetterFile,
    }));

    const params2 = value.newBusinesses.map((addedBusiness: IAddBusinessInput) => ({
      index:        addedBusiness.index,
      id:           invitation.id,
      businessName: addedBusiness.businessName,
      documentType: 'BUSINESS_LICENSE',
      file:         addedBusiness.businessLicenseFile,
    }));

    const params3 = value.newBusinesses.map((addedBusiness: IAddBusinessInput) => ({
      index:        addedBusiness.index,
      id:           invitation.id,
      businessName: addedBusiness.businessName,
      documentType: 'AUTHORIZED_SIGNATORY_DRIVER_LICENSE',
      file:         addedBusiness.driverLicenseFile,
    }));

    const params: any[] = params1.concat(params2, params3);
    runMutation(params);
  }, [invitation.id, runMutation]);

  const handleBulkDone = useCallback(() => {
    backboneNavigateToUrl(selfOnboardingSuccessPath(0));
  }, []);

  const handleFileUploaded = useCallback((value: boolean, businessNames: string[]) => {
    setUploadedBusinessDetails(value);
    setParsedBusinessNames(businessNames);
  }, []);

  if (isSuccess) {
    return (
      <div className={ styles['qbo-success'] }>
        <SuccessIcon fontSize={ 80 } mt={ 80 } />
        <h2 className="font-18 in-black m-t-30">Successfully submitted the business details.</h2>
        <span className="m-t-10">As a next step, we&apos;ll send you an email related to your</span>
        <span className="m-t-5">existing accounting software shortly.</span>
      </div>
    );
  }

  return (
    <>
      {errorMsg
        && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } />}
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="KYC Documents"
        title="Upload KYC Documents"
        onDone={ handleBulkDone }
      />
      <div className={ styles['self-onboarding-request-document-container'] }>
        <div className={ styles['tasks-container'] }>
          <h2>
            Hey
            { ' ' }
            { window.Docyt.currentAdvisor.get('first_name') }
            { ', ' }
            help us with the below onboarding details!
          </h2>
          <p className="font-12 in-grey-1050 m-b-20">
            The information you provide will be handled with the utmost confidentiality and
            { ' ' }
            used exclusively for the purpose of enhancing your experience with Docyt.
          </p>
          <BusinessDetails
            invitation={ invitation }
            onFileUploaded={ handleFileUploaded }
          />
          { uploadedBusinessDetails && (
            <KYCDocuments
              formId={ formId }
              parsedBusinessNames={ parsedBusinessNames }
              onSubmit={ handleSubmit }
            />
          )}
        </div>
        <div className="setup-client-footer">
          <Button
            className="pull-right bg-purple-1000 in-white width-180px"
            data-color="$purple-1000"
            disabled={ !uploadedBusinessDetails }
            form={ formId }
            type="submit"
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default RequestDocumentView;
