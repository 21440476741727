import React, { useCallback, useMemo, useState } from 'react';

import flatten from 'lodash/flatten';
import omit from 'lodash/omit';

import { useSplitTransactionServiceDocumentFayeChannel } from '@src/hooks/faye/split_transaction_service_document';
import { useUndoSplitTransactionServiceDocumentFayeChannel } from '@src/hooks/faye/undo_split_transaction_service_document';
import { useGetTransactionServiceDocuments } from '@src/hooks/queries/transaction_service_documents';
import { useURLParams } from '@src/hooks/url_params';
import { TID } from '@src/types/common';
import { ISplitTransactionServiceDocumentFayeEvent } from '@src/types/docyt_events/split_transaction_service_document';
import { IUndoSplitTransactionServiceDocumentFayeEvent } from '@src/types/docyt_events/undo_split_transaction_service_document';
import { ISortingParams } from '@src/types/sorting';
import { TTransactionServiceDocumentsSortColumn } from '@src/types/transaction_service_documents';

import { ExportCSVBanner } from '@src/components/common/actions/export_csv';
import Filter from '@src/components/ui/filter';

import TransactionsFilter, { ITransactionsFilter } from './filter';
import Header from './header';
import DuplicatedInfo from './transactions_duplicated_info';
import PaginationInfo from './transactions_pagination_info';
import Table from './transactions_table';

interface IBankingAccountsTransactionsProps {
  businessId: TID,
  isAdmin: boolean,
  isTrash: boolean,
}

const DefaultSorting: ISortingParams<TTransactionServiceDocumentsSortColumn> = {
  orderColumn:    'transaction_date',
  orderDirection: 'desc',
};

const getTransactionsParamsFromFilter = (filterData: ITransactionsFilter | undefined) => {
  const filterParams = omit(filterData || {}, 'excluded');

  let transactionsState: 'unverified' | 'verified' | undefined;
  if (filterData?.transaction_type && filterData.transaction_type.length > 0) {
    if (filterData.transaction_type[0] === window.configData.transaction_types.UNVERIFIED) {
      transactionsState = 'unverified';
      delete filterParams.transaction_type;
    } else {
      transactionsState = 'verified';
    }
  }

  return {
    filter:   filterParams,
    excluded: filterData?.excluded,
    state:    transactionsState,
  };
};

const BankingAccountsTransactions = ({
  businessId,
  isAdmin,
  isTrash,
}: IBankingAccountsTransactionsProps) => {
  const [eventData, setEventData] = useState<
    ISplitTransactionServiceDocumentFayeEvent | IUndoSplitTransactionServiceDocumentFayeEvent | null
  >(null);
  const { filter, sorting } = useURLParams<
    ITransactionsFilter,
    TTransactionServiceDocumentsSortColumn
  >({
    businessId,
    section: isTrash
      ? window.Docyt.Common.Constants.ACCOUNT_TYPES.TRASH_TRANSACTION_TYPE
      : window.Docyt.Common.Constants.ACCOUNT_TYPES.TRANSACTION_TYPE,
    defaultSorting: DefaultSorting,
  });

  const filterParams = useMemo(() => {
    return getTransactionsParamsFromFilter(filter.data);
  }, [filter.data]);

  const queryParams = {
    businessId,
    withoutScope:                   true,
    withDuplicateOriginTransaction: true,
    isTrash,
    ...filterParams,
    ...sorting.data,
  };
  const transactionsQuery = useGetTransactionServiceDocuments(queryParams);
  const transactionPages = useMemo(() => {
    return transactionsQuery.data?.pages || [];
  }, [transactionsQuery.data?.pages]);

  const transactions = useMemo(() => {
    return flatten(transactionPages.map(
      (p) => {
        if (eventData?.eventName === 'split_transaction_service_document') {
          return p.collection.filter((item) => {
            return item.id !== eventData.event.transactionServiceDocumentId;
          });
        }

        if (eventData?.eventName === 'undo_split_transaction_service_document') {
          return p.collection.filter((item) => {
            return !eventData.event.transactionServiceDocumentIds.includes(item.id);
          });
        }

        return p.collection;
      },
    ));
  }, [transactionPages, eventData]);

  const duplicatedCount = useMemo(() => {
    return transactionPages[0]?.meta.duplicatedCount || 0;
  }, [transactionPages]);

  const { refetch: refetchTransactions } = transactionsQuery;
  const handleDataReceived = useCallback((fayeEvent) => {
    refetchTransactions();
    setEventData(fayeEvent);
  }, [refetchTransactions]);

  useSplitTransactionServiceDocumentFayeChannel(businessId, handleDataReceived);
  useUndoSplitTransactionServiceDocumentFayeChannel(businessId, handleDataReceived);

  return (
    <Filter<ITransactionsFilter> filter={ filter }>
      <div className="page-main width-100-percent">
        <Header
          exportParams={ queryParams }
          isAdmin={ isAdmin }
          isTrash={ isTrash }
        />
        <div className="page-main-body">
          <ExportCSVBanner atomKey="bankingAccountTransactions" />
          {
            !isTrash && (duplicatedCount > 0) && (
              <DuplicatedInfo
                businessId={ businessId }
                duplicatedCount={ duplicatedCount }
                // TODO: queryKey was removed in Duplicated_transactions Table
                onResolved={ () => transactionsQuery.refetch() }
              />
            )
          }
          <div className="banking-accounts-table-header">
            <Filter.ResetButton showFilteredCount title="Reset Filter" />
            <PaginationInfo data={ transactionsQuery?.data } />
          </div>
          <div className="banking-accounts-table-wrapper">
            <TransactionsFilter
              filter={ filter }
            />
            <Table
              businessId={ businessId }
              isAdmin={ isAdmin }
              isTrash={ isTrash }
              query={ transactionsQuery }
              sorting={ sorting }
              transactions={ transactions }
            />
          </div>
        </div>
      </div>
    </Filter>
  );
};

export default React.memo(BankingAccountsTransactions);
