import React, { useState } from 'react';

import isNull from 'lodash/isNull';

import { TID } from '@src/types/common';
import { LDFeatureForBusinessEnabled, LDFeatureForUserEnabled, LDFeatureValueForBusiness, LDFeatureValueForUser } from '@src/utils/config';

import Root from '@src/components/root';

import GlobalNavigationHeaderActions from './global_navigation_header_actions';
import Actions from './module_header_actions';

interface IModuleHeaderPageProps {
  businessId: TID,
  currentModulePath?: string,
  focusToMyClient: boolean,
  hasVaultServiceRole: boolean,
}

const ModuleHeaderPage = ({
  businessId,
  currentModulePath,
  focusToMyClient,
  hasVaultServiceRole,
}: IModuleHeaderPageProps) => {
  const [globalNavigationBusinessEnabled, setGlobalNavigationBusinessEnabled] =
    useState<string | boolean | undefined>(
      LDFeatureValueForBusiness(
        window.Docyt.Common.Constants.GLOBAL_NAVIGATION,
        String(businessId),
      ),
    );

  if (globalNavigationBusinessEnabled === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.GLOBAL_NAVIGATION,
      String(businessId),
    ).then((res) => {
      setGlobalNavigationBusinessEnabled(res);
    });
  }

  const [globalNavigationUserEnabled, setGlobalNavigationUserEnabled] = useState<
    string | boolean | undefined>(
      LDFeatureValueForUser(
        window.Docyt.Common.Constants.GLOBAL_NAVIGATION,
        String(window.Docyt.currentAdvisor.id),
      ),
    );

  if (globalNavigationUserEnabled === undefined) {
    LDFeatureForUserEnabled(
      window.Docyt.Common.Constants.GLOBAL_NAVIGATION,
      String(window.Docyt.currentAdvisor.id),
    ).then((res) => {
      setGlobalNavigationUserEnabled(res);
    });
  }

  const isGlobalNavigationEnabled: boolean = !!((globalNavigationUserEnabled || globalNavigationBusinessEnabled));

  return (
    <Root>
      {(isGlobalNavigationEnabled && isNull(focusToMyClient)) ? (
        <GlobalNavigationHeaderActions
          businessId={ businessId }
          currentModulePath={ currentModulePath }
          focusToMyClient={ focusToMyClient }
          isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
        />
      ) : (
      <Actions
        businessId={ businessId }
        currentModulePath={ currentModulePath }
        focusToMyClient={ focusToMyClient }
        hasVaultServiceRole={ hasVaultServiceRole }
      />
      )}
    </Root>
  );
};

export default ModuleHeaderPage;
