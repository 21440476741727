import React from 'react';

import Root from '@src/components/root';
import SelfOnboardingMainView from '@src/components/self_onboarding/self_onboarding_main_view';
import Sidemenu from '@src/components/self_onboarding/sidemenu/sidemenu';
import PageContainer from '@src/components/ui_v2/layout/page_container/page_container';

interface ISelfOnboardingPageProps {
  step: number,
  invitation: any,
  isSuccess: boolean,
}

const SelfOnboardingPage = ({
  step,
  invitation,
  isSuccess,
}: ISelfOnboardingPageProps): JSX.Element => {
  return (
    <Root>
      <PageContainer className="self-onboarding-invitation-container" sidemenu={ <Sidemenu step={ step } /> }>
        <SelfOnboardingMainView invitation={ invitation } isSuccess={ isSuccess } step={ step } />
      </PageContainer>
    </Root>
  );
};

export default SelfOnboardingPage;
