module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<span id="typing-notification-panel"></span>\n\n<div class="chat-rephrased-message hidden clearfix">\n  <div id="chat-rephrased-message-text"></div>\n\n  <div class="rephrased-message-actions">\n    <div class="p-l-26">\n      <i class=\'icon-bot-notification-icon font-22\'><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></i>\n\n      Powered By Docyt AI\n    </div>\n\n    <div>\n      <a class="pointer font-semi-bold m-r-16" id="hide-rephrased-message">Hide</a>\n\n      <button class="btn btn-blue" type="button" id="accept-rephrased-message">Accept</button>\n    </div>\n  </div>\n</div>\n\n<div class="display-flex">\n  <div id="message-input" class="inputor added-input" contentEditable="true" style="overflow-y:auto" placeholder="Type your message"></div>\n  <button class=\'added-btn\' type=\'button\' id="show-chat-menu-bar">\n    <i class=\'icon icon-plus\'></i>\n    <div class="added-context-menu" style="display: none;">\n      <input type="file" class="input-file">\n      <a class="upload-file"><i class="icon icon-upload"></i>Upload a document</a>\n    </div>\n  </button>\n\n  ');
    
      if (this.genAIEnabled) {
        __out.push('\n  <button class=\'chat-ai-prompt-btn\' disabled type=\'button\' id="ai-prompt-btn" title="Powered By Docyt AI">\n    <i class="icon-bot-notification-icon font-22"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></i >\n    <i class="fa fa-spinner" style="display: none" />\n  </button>\n  ');
      }
    
      __out.push('\n\n  <button class=\'chat-send-message-btn\' type=\'button\' id="send-message">\n    <i class=\'icon icon-letter-send\'></i>\n  </button>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}