import React from 'react';

import { selfOnboardingPath } from '@src/routes';

import ServiceSidebar from '@src/components/ui/service_sidebar';

interface ISelfOnboardingSideMenuProps {
  step: number,
}

const SelfOnboardingSidemenu = ({
  step,
}: ISelfOnboardingSideMenuProps) => {
  return (
    <ServiceSidebar>
      <ServiceSidebar.Item
        active={ step === 0 }
        href={ selfOnboardingPath(0) }
        title="Business Details"
      />
      <ServiceSidebar.Item
        active={ step === 3 }
        href={ selfOnboardingPath(3) }
        title="Billing Info"
      />
      <ServiceSidebar.Item
        active={ step === 1 }
        href={ selfOnboardingPath(1) }
        title="Accounting Software"
      />
      <ServiceSidebar.Item
        active={ step === 5 }
        href={ selfOnboardingPath(5) }
        title="Connect Banks"
      />
      <ServiceSidebar.Item
        active={ step === 6 }
        href={ selfOnboardingPath(6) }
        title="Payment Methods"
      />
      <ServiceSidebar.Item
        active={ step === 2 }
        href={ selfOnboardingPath(2) }
        title="Add Signature"
      />
      <ServiceSidebar.Item
        active={ step === 4 }
        href={ selfOnboardingPath(4) }
        title="Deposit Verification"
      />
    </ServiceSidebar>
  );
};

export default SelfOnboardingSidemenu;
