import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetManagementGroupsParams,
  IGetManagementGroupsResponse,
  IDeleteManagementGroupParams,
  IAddManagementGroupParams,
  IUpdateManagementGroupParams,
  IGetManagementGroupByBusinessIdResponse,
  IGetManagementGroupResponse,
  getManagementGroups,
  deleteManagementGroup,
  addManagementGroup,
  updateManagementGroup,
  getManagementGroupByBusinessId,
  getManagementGroup,
  getManagementGroupsWithNotificationSetting,
  getManagementDefaultAggregator,
  IGetManagementGroupDefaultAggregatorResponse,
} from '@src/requests/management_groups';
import { TID } from '@src/types/common';
import { IManagementGroup } from '@src/types/management_groups';

import {
  createUseGetInfiniteCollection,
} from './infinite_collection_queries';

const MANAGEMENT_GROUPS_QUERY = 'management_groups';
const MANAGEMENT_GROUP_BY_BUSINESS_ID_QUERY = 'management_group_by_business_id';

const useGetManagementGroups = createUseGetInfiniteCollection<
  IManagementGroup,
  IGetManagementGroupsParams,
  IGetManagementGroupsResponse
>({
  queryKey: MANAGEMENT_GROUPS_QUERY,
  request:  getManagementGroups,
});

const useGetManagementGroupsWithNotificationSetting = createUseGetInfiniteCollection<
  IManagementGroup,
  IGetManagementGroupsParams,
  IGetManagementGroupsResponse
  >({
    queryKey: QueryKey.managementGroupsWithNotificationSetting,
    request:  getManagementGroupsWithNotificationSetting,
  });

const useGetManagementGroup = (id?: TID) => {
  return useQuery<IGetManagementGroupResponse, Error>(
    [QueryKey.managementGroupById, id],
    () => getManagementGroup(id),
    {
      enabled: Boolean(id),
    },
  );
};

const useGetManagementGroupByBusinessId = (businessId?: TID) => {
  return useQuery<IGetManagementGroupByBusinessIdResponse, Error>(
    [MANAGEMENT_GROUP_BY_BUSINESS_ID_QUERY, businessId],
    () => getManagementGroupByBusinessId(businessId),
    {
      enabled: Boolean(businessId),
    },
  );
};

const useDestroyManagementGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteManagementGroupParams>(
    deleteManagementGroup,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MANAGEMENT_GROUPS_QUERY);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    },
  );
};

const useAddManagementGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IAddManagementGroupParams>(
    addManagementGroup,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MANAGEMENT_GROUPS_QUERY);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    },
  );
};

const useUpdateManagementGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdateManagementGroupParams>(
    updateManagementGroup,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MANAGEMENT_GROUPS_QUERY);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    },
  );
};

const useGetManagementGroupDefaultAggregator = (managementGroupId?: TID) => {
  return useQuery<IGetManagementGroupDefaultAggregatorResponse, Error>(
    [MANAGEMENT_GROUPS_QUERY, managementGroupId],
    () => {
      // showring spinner while api callingß
      window.Docyt.vent.trigger("show:spinner")
      return getManagementDefaultAggregator(Number(managementGroupId))
    },
    {
      enabled: Boolean(managementGroupId),
      onSettled:() => {
        window.Docyt.vent.trigger("hide:spinner")
      }
    },
  );
};

export {
  useGetManagementGroups,
  useGetManagementGroupsWithNotificationSetting,
  useDestroyManagementGroup,
  useAddManagementGroup,
  useUpdateManagementGroup,
  useGetManagementGroupByBusinessId,
  useGetManagementGroup,
  useGetManagementGroupDefaultAggregator,
};
