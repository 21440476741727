import React from 'react';

import { useGetMultiBusinessReportItemAccountValuesQuery } from '@src/hooks/queries/report_service/multi_business_report';
import { IBusiness } from '@src/types/businesses';
import { IReport } from '@src/types/report_service/report';

import QueryStatus from '@src/components/utils/query_status';

import List from './list';

interface IMultiEntityItemAccountValuesDetailsProps {
  multiBusinessReportId: string,
  from: string,
  to: string,
  itemIdentifier: string,
  businesses: IBusiness[],
  reports: IReport[],
}

const MultiEntityItemAccountValuesDetails = ({
  multiBusinessReportId,
  from,
  to,
  itemIdentifier,
  businesses,
  reports,
}: IMultiEntityItemAccountValuesDetailsProps): JSX.Element => {
  const valuesQuery = useGetMultiBusinessReportItemAccountValuesQuery({
    multiBusinessReportId,
    from,
    to,
    itemIdentifier,
  });

  const aggregatedItemAccountValues = valuesQuery.data?.aggregatedItemAccountValues || [];
  const businessItemAccountValues = valuesQuery.data?.businessItemAccountValues || [];

  return (
    <>
      <QueryStatus query={ valuesQuery } />
      <List
        aggregatedItemAccountValues={ aggregatedItemAccountValues }
        businessItemAccountValues={ businessItemAccountValues }
        businesses={ businesses }
        from={ from }
        itemIdentifier={ itemIdentifier }
        multiBusinessReportId={ multiBusinessReportId }
        reports={ reports }
        to={ to }
      />
    </>
  );
};

export {
  IMultiEntityItemAccountValuesDetailsProps,
  MultiEntityItemAccountValuesDetails as default,
};
