import React from 'react';

import Modal from '@src/components/ui/modal';

import Content from './bulk_action_content';
import { IBulkActionStatus } from './hooks';

interface IBulkActionModalProps<TRequestParams, TRequestResponse> {
  isOpen: boolean,
  itemsTitle: string,
  title: string,
  needTitleSuffix?: boolean,
  status: IBulkActionStatus<TRequestParams, TRequestResponse>,
  result?: (status: IBulkActionStatus<any, any>) => React.ReactNode
  onCancel: () => void,
  onDone: (status?: IBulkActionStatus<any, any>) => void,
}

const BulkActionModal = <TRequestParams, TRequestResponse>({
  isOpen,
  itemsTitle,
  status,
  title,
  needTitleSuffix = true,
  result,
  onCancel,
  onDone,
}: IBulkActionModalProps<TRequestParams, TRequestResponse>): JSX.Element => {
  return (
    <Modal
      show={ isOpen }
      title={ needTitleSuffix ? `${title} in Progress` : title }
    >
      { isOpen && (
        <Content
          itemsTitle={ itemsTitle }
          result={ result }
          status={ status }
          onCancel={ onCancel }
          onDone={ onDone }
        />
      ) }
    </Modal>
  );
};

export {
  IBulkActionModalProps,
  BulkActionModal as default,
};
