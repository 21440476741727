/* eslint-disable eqeqeq */
import React from 'react';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';

import styles from '../../navigation_styles.module.scss';

interface IServiceMenuProps {
  activeItem?: string,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

const ReportsItem = ({
  activeItem,
  focusToMyClient,
  isGlobalNavigationEnabled
}: IServiceMenuProps) => {
  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.REPORTS }
      focusToMyClient={ focusToMyClient }
      isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
      title={ window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.REPORTS }
    >
      <li className='p-t-8'>
        <span className={ `m-l-18 m-b-8 ${styles['dropdown-item-title']}` }>
          { window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.REPORTS.toUpperCase() }
        </span>
      </li>
      <ServiceItem
        serviceUrl=''
        title='TEST'
      />
    </ModuleHeaderMenu>
  );
};

export default ReportsItem;
