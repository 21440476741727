import React, { useCallback, useMemo, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import toastr from '@lib/toastr';
import {
  useCreateBankAccountFromPlaid,
  useCreateCreditCard,
  useGetBillingBankAccountsByBusinessIds,
  useGetBillingCreditCardsByBusinessIds,
} from '@src/hooks/queries/billing_payment_methods';
import { useGetSubscriptionsByBusinessIds } from '@src/hooks/queries/subscriptions';
import {
  ICreateBankAccountFromPlaidParams,
  ICreateCreditCardParams,
} from '@src/requests/billing_payment_methods';
import { IBillingBankAccount, IBillingCreditCard } from '@src/types/billing_payment_methods';
import { TID } from '@src/types/common';
import {
  ISelfOnboardingBusiness,
  ISelfOnboardingInvitation,
} from '@src/types/self_onboarding_invitation';
import { ISubscription } from '@src/types/subscriptions';

import { Button } from '@src/components/ui/buttons';
import SideView from '@src/components/ui/side_view';
import Table from '@src/components/ui/table/table';
import { SuccessIcon } from '@src/components/utils/icomoon';

import BillingInfoListItem from './billing_info_item';
import useProgressAddModal from './progress_modal';

import styles from './styles.module.scss';

const stripePromise = loadStripe(window.configData.stripePublishableKey || '');

interface IBillingInfoListProps {
  invitation: ISelfOnboardingInvitation,
  isSuccess: boolean,
}

const getBusinessSubscription = (
  subscriptions: ISubscription[],
  businessId: TID,
): ISubscription | undefined => {
  return subscriptions.find((subscription) => subscription.businessId === businessId);
};

const getBankAccount = (
  bankAccounts: IBillingBankAccount[],
  subscription?: ISubscription,
): IBillingBankAccount | undefined => {
  if (subscription === undefined || subscription.paymentMethodType !== 'BankAccount') return undefined;
  return bankAccounts.find((bankAccount) => bankAccount.id === subscription.paymentMethodId);
};

const getCreditCard = (
  creditCards: IBillingCreditCard[],
  subscription?: ISubscription,
): IBillingCreditCard | undefined => {
  if (subscription === undefined || subscription.paymentMethodType !== 'CreditCard') return undefined;
  return creditCards.find((creditCard) => creditCard.id === subscription.paymentMethodId);
};

const getBillingInfoItem = (
  business: ISelfOnboardingBusiness,
  subscriptions: ISubscription[],
  bankAccounts: IBillingBankAccount[],
  creditCards: IBillingCreditCard[],
  onAddNewAccount: (newAccount: ICreateBankAccountFromPlaidParams | ICreateCreditCardParams) => void,
): JSX.Element => {
  const subscription = getBusinessSubscription(subscriptions, business.id);
  const bankAccount = getBankAccount(bankAccounts, subscription);
  const creditCard = getCreditCard(creditCards, subscription);

  return (
    <BillingInfoListItem
      key={ business.id }
      bankAccount={ bankAccount }
      business={ business }
      creditCard={ creditCard }
      onAddNewAccount={ onAddNewAccount }
    />
  );
};

const BillingInfoList = ({
  invitation,
  isSuccess,
}: IBillingInfoListProps) => {
  const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(isSuccess);

  const progressModal = useProgressAddModal();
  const createBankAccount = useCreateBankAccountFromPlaid();
  const createCreditCard = useCreateCreditCard();
  const businessIds = invitation.businesses.map((p) => p.id);
  const subscriptionsQuery = useGetSubscriptionsByBusinessIds(businessIds);
  const bankAccountsQuery = useGetBillingBankAccountsByBusinessIds(businessIds);
  const creditCardsQuery = useGetBillingCreditCardsByBusinessIds(businessIds);
  const subscriptions = useMemo(() => subscriptionsQuery.data || [], [subscriptionsQuery]);
  const bankAccounts = useMemo(() => bankAccountsQuery.data || [], [bankAccountsQuery]);
  const creditCards = useMemo(() => creditCardsQuery.data || [], [creditCardsQuery]);
  const newAccounts = useMemo(() => {
    return [] as (ICreateBankAccountFromPlaidParams | ICreateCreditCardParams)[];
  }, []);
  const [doneCount, setDoneCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handleAddNewAccount = useCallback((newAccount) => {
    newAccounts[newAccount.businessId] = newAccount;

    let count = 0;
    Object.keys(newAccounts).forEach((key) => {
      if (key) count += 1;
    });
    setTotalCount(count);
  }, [newAccounts]);

  const submit = () => {
    Backbone.history.navigate('/self_onboarding?step=3&success=1', { trigger: false, replace: true });
    setShowSuccessBanner(true);
  };

  const { mutateAsync: mutateBankAccount } = createBankAccount;
  const { mutateAsync: mutateCreditCad } = createCreditCard;

  const onConfirm = async () => {
    progressModal.open();
    try {
      const createPromises = newAccounts
        .map((account) => {
          if ('plaidPublicToken' in account) {
            return mutateBankAccount(account).then(() => {
              setDoneCount((count) => count + 1);
            });
          }

          return mutateCreditCad(account).then(() => {
            setDoneCount((count) => count + 1);
          });
        });
      await Promise.all(createPromises);
      progressModal.props.onDone();
      submit();
    } catch (error) {
      const errorMessage = (error as Error)?.message || 'An unknown error occurred';
      progressModal.props.onDone();
      toastr.error(`Failed to add: ${errorMessage}`, 'Error');
    }
  };

  if (showSuccessBanner) {
    return (
      <div className={ styles['billing-info-success'] }>
        <SuccessIcon fontSize={ 80 } mt={ 80 } />
        <h2 className="font-18 in-black m-t-30">Successfully added the billing info.</h2>
        <span className="m-t-10">As a next step,</span>
        <span className="m-t-5">we’ll deposit a micro amounts into your operating bank account(s).</span>
        <span className="m-t-5">You will receive an email and this might take upto 24~48 hours.</span>
      </div>
    );
  }

  return (
    <Elements stripe={ stripePromise }>
      <div className={ styles['self-onboarding-qbo-container'] }>
        <SideView.Provider>
          <div className="display-flex-column width-100-percent height-100-percent">
            <div className="tasks-container">
              <h2>Time to streamline billing!</h2>
              <p className="font-11 in-grey-1050">
                Add your preferred payment method for your Docyt plans to ensure seamless experience.
                To stop being billed, you need to cancel the current subscription.
              </p>
              <div className="tasks-view">
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.HCell
                        className={ styles['title-cell'] }
                        textAlign="left"
                        widthPercent={ 40 }
                      >
                        Business Name
                      </Table.HCell>
                      <Table.HCell
                        className={ styles['title-cell'] }
                        textAlign="right"
                        widthPercent={ 60 }
                      >
                        Billing Method
                      </Table.HCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {
                      invitation.businesses.map((business) => (
                        getBillingInfoItem(
                          business,
                          subscriptions,
                          bankAccounts,
                          creditCards,
                          handleAddNewAccount,
                        )
                      ))
                    }
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div className="setup-client-footer">
              <Button
                className="pull-right bg-purple-1000 in-white width-180px"
                data-color="$purple-1000"
                onClick={ onConfirm }
              >
                Save and Submit
              </Button>
            </div>
          </div>
          <SideView.Render />
        </SideView.Provider>
      </div>
      <progressModal.Component
        doneCount={ doneCount }
        totalCount={ totalCount }
        { ...progressModal.props }
      />
    </Elements>
  );
};

export default BillingInfoList;
