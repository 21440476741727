module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' document-checkable pointer transaction-select-checkbox-column" style="width: 30px;">\n  <div class="checkbox checkbox-primary">\n    <input type="checkbox" class="pointer check-transaction" ');
    
      __out.push(__sanitize(this.is_checked ? 'checked' : ''));
    
      __out.push('>\n    <label></label>\n  </div>\n</td>\n<td class="relative document-clickable pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  <span class="font-14 inline-block">\n    ');
    
      __out.push(__sanitize(moment(this.transaction_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
    
      __out.push('\n  </span>\n</td>\n<td class="document-clickable pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  ');
    
      __out.push(__sanitize(this.description));
    
      __out.push('\n</td>\n<td class="document-clickable pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  ');
    
      __out.push(__sanitize(this.payment_account_name));
    
      __out.push('\n</td>\n<td class="document-clickable pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  ');
    
      __out.push(__sanitize(this.sub_type_value));
    
      __out.push('\n</td>\n<td class="document-clickable pointer wrap-ellipsis ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  <span class="category-name" title="');
    
      __out.push(__sanitize(this.categoryWithDepartment));
    
      __out.push('">\n    ');
    
      __out.push(__sanitize(this.categoryWithDepartment));
    
      __out.push('\n  </span>\n</td>\n<td class="document-clickable pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' relative text-right">\n  <span class="font-16 font-semi-bold">');
    
      __out.push(__sanitize(parseFloat(this.amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      })));
    
      __out.push('</span>\n</td>\n<td style="width:50px; text-align:center" class="');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  ');
    
      if (this.flagged_state === 'open') {
        __out.push('\n    <span class="icon-question-mark-orange font-18"><span class="path1"></span><span class="path2"></span></span>\n  ');
      } else if (this.flagged_state === 'resolved') {
        __out.push('\n    <span class="icon-success font-18"><span class="path1"></span><span class="path2"></span></span>\n  ');
      }
    
      __out.push('\n</td>\n<td class="');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' document-clickable pointer vertical-align-middle-important" style="width:50px">\n  ');
    
      if (this.has_documents) {
        __out.push('\n    <span class="icon-documented font-22"></span>\n  ');
      } else if (this.no_document_required) {
        __out.push('\n    <span class="icon-no_document_needed font-22"></span>\n  ');
      } else {
        __out.push('\n    <span class="icon-document_grey font-18 m-l-2"><span class="path1"></span><span class="path2"></span></span>\n  ');
      }
    
      __out.push('\n</td>\n<td class="');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' document-clickable pointer verified_by" style="width:50px">\n  ');
    
      if (this.verified_by_user) {
        __out.push('\n    <span class="icon-verified-manually font-22" title="Verified manually"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n  ');
      } else {
        __out.push('\n    <span class="icon-bot-notification-icon font-22 title="Verified automatically""><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>\n  ');
      }
    
      __out.push('\n</td>\n<td style="width:30px" class="qbo-status-icon-column document-clickable pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('"></td>\n<td style="width:50px" class="pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  <div class="relative">\n    <a class="btn dropdown-toggle" type="button" id="transaction-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <span class="fa fa-ellipsis-h font-15 in-grey-800"></span>\n    </a>\n    <ul class="dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="transaction-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n      <li><a tabindex="-1" class="pointer add-document"><span class="icon-plus-circle font-18 m-r-18 vertical-align-middle-important"></span>Add Document</a></li>\n      <hr class="m-t-5 m-b-5"/>\n      <li><a tabindex="-1" class="pointer not-equity-btn"><span class="icon-not-a-chargeback-icon font-18 m-r-18"></span> Uncategorize</a></li>\n      <li><a tabindex="-1" href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('/chat" class="send-message-btn"><span class="icon-chat-round m-r-18"></span> Send a Message</a></li>\n      <li><a tabindex="-1" href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('" class="view-transaction-detail"><i class="fa fa-file-text-o m-l-3 m-r-18"></i> <span>View Transaction Detail</span></a></li>\n      ');
    
      if (!this.no_document_required) {
        __out.push('\n      <li><a tabindex="-1" class="pointer no-required"><span class="icon-mark-as-matched font-18 m-r-18 vertical-align-middle-important"></span>No document needed</a></li>\n      ');
      }
    
      __out.push('\n      ');
    
      if (this.service.get('user_role').role === Docyt.Common.Constants.RECONCILIATION_ROLE['ACCOUNTANT']) {
        __out.push('\n        ');
        if (this.flagged_state === 'open') {
          __out.push('\n          <li><a tabindex="-1" class="pointer review-transaction"><span class="icon-check_circle_large font-16 m-r-21 vertical-align-middle-important"></span>Mark as Reviewed</a></li>\n        ');
        }
        __out.push('\n        ');
        if (!this.flagged_state) {
          __out.push('\n          <li><a tabindex="-1" class="pointer flag-transaction"><span class="icon-docyt-support font-16 m-l-2 m-r-19 vertical-align-middle-important"></span>Flag for Client Review</a></li>\n        ');
        } else {
          __out.push('\n          <li><a tabindex="-1" class="pointer close-flag-transaction"><span class="icon-cross-icon font-16 m-l-2 m-r-19 vertical-align-middle-important"></span>Close Issue</a></li>\n        ');
        }
        __out.push('\n      ');
      }
    
      __out.push('\n    </ul>\n  </div>\n</td>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}