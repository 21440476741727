import React from 'react';

import { Routes, Route } from 'react-router-dom';

import Details from './business_metric_value_details';
import DimensionDetails from '../business_metric_dimension_details';
import BusinessMetricProvider from '../business_metric_provider';

const BusinessMetricValuesRouter = (): JSX.Element | null => {
  return (
    <Routes>
      <Route path="businesses/:businessId/metrics_service">
        <Route element={ <BusinessMetricProvider /> } path=":businessMetricId">
          <Route path="values">
            <Route element={ <DimensionDetails /> } path=":date" />
            <Route index element={ <Details /> } />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default React.memo(BusinessMetricValuesRouter);
