import { useMemo } from 'react';

import { omit } from 'lodash';
import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { useGetTransactionServiceDocuments } from '@src/hooks/queries/transaction_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetTransactionServiceDocumentsResponse } from '@src/requests/transaction_service_documents';
import { TID, TSection } from '@src/types/common';
import { TFilterData } from '@src/types/filter';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn } from '@src/types/transaction_service_documents';

import { TSubTransactionTypeOption } from '@src/components/common_v2/filter_fields/transaction_type_filter_field';
import { useFilterData } from '@src/components/ui_v2/filter';

interface IUseTSDCollectionParams {
  businessId: TID,
  section: TSection,
  params: TFilterData,
}

interface ITSDCollection {
  query: UseInfiniteQueryResult<IGetTransactionServiceDocumentsResponse, Error>,
  records: ITransactionServiceDocument[],
  section: TSection,
  sorting: ISorting<TTransactionServiceDocumentsSortColumn>,
}

const tsdDefaultSorting: ISortingParams<TTransactionServiceDocumentsSortColumn> = {
  orderColumn:    'transaction_date',
  orderDirection: 'desc',
};

const sanitizeFilterParams = (filterData: Partial<TSubTransactionTypeOption>) => {
  const filterParams = omit(filterData || {}, 'excluded', 'state');

  return {
    filter:   filterParams,
    excluded: filterData?.excluded,
    state:    filterData?.state as string,
  };
};

const useTSDCollection = ({
  businessId,
  section,
  params,
}: IUseTSDCollectionParams): ITSDCollection => {
  const filterData = useFilterData(section, params);
  const filterParams = useMemo(() => sanitizeFilterParams(filterData), [filterData]);

  const sorting = useSorting<TTransactionServiceDocumentsSortColumn>({
    section:        section.section,
    defaultSorting: tsdDefaultSorting,
  });

  const query = useGetTransactionServiceDocuments({
    businessId,
    ...filterParams,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  ITSDCollection,
  useTSDCollection,
};
