import React, { useMemo, useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useReportServiceContext } from '@src/hooks/contexts/reports_service_context';
import { useGetLightReportsList, useGetReportTemplates } from '@src/hooks/queries/report_service/custom_reports';
import {
  basicReportsPath,
  viewReportPath,
  revenueReportsPath,
  expenseReportsPath,
  managementReportsPath,
  budgetsPath,
  departmentReportsPath,
  laborReportsPath,
} from '@src/routes';
import { TReportCategory, IReport, ITemplate } from '@src/types/report_service/report';

import ServiceMenu from '@src/components/common/service_menu';
import ServiceSidebar from '@src/components/ui/service_sidebar';
import {
  ReportBudgetIcon,
  ReportBasicIcon,
  ReportDepartmentIcon,
  ReportExpenseIcon,
  ReportManagementIcon,
  ReportRevenueIcon,
  ReportLaborIcon,
} from '@src/components/utils/icomoon';

interface ISideMenuProps {
  category: TReportCategory,
}

const Sidemenu = ({
  category,
}: ISideMenuProps): JSX.Element => {
  const business = useBusinessContext();
  const service = useReportServiceContext();
  const standardCategoryIds = window.Docyt.Common.Constants.STANDARD_CATEGORY_ID;
  const reportsQuery = useGetLightReportsList(service.id);
  const reports = useMemo(() => reportsQuery.data || [], [reportsQuery]);
  const reportTemplatesQuery = useGetReportTemplates(business.standardCategoryId);
  const templates = useMemo(() => reportTemplatesQuery.data || [], [reportTemplatesQuery]);
  const featureFlagforLabor = useMemo(
    () =>
      window.configData.launch_darkly_enabled_global_features.includes(
        window.Docyt.Common.Constants.BUSINESS_LABOR_REPORT_V2_ENABLED
      ),
    []
  );

  const filterReports = useCallback((reportCategory) => {
    return reports.filter((report) => {
      return templates.find((t) => t.id === report.templateId && t.category === reportCategory);
    });
  }, [reports, templates]);

  const getScheduleNumber = (name: string) => {
    const match = name.match(/Schedule (\d+)/i);
    return match ? parseInt(match[1], 10) : null;
  };

  const sortReportsByTemplateName = (departmentReports: IReport[]) => {
    const getTemplateById = (id: string) => templates.find((template: ITemplate) => template.id === id);

    const sortFunction = (a: IReport, b: IReport) => {
      const templateA = getTemplateById(a.templateId);
      const templateB = getTemplateById(b.templateId);

      const nameA = templateA ? templateA.name : '';
      const nameB = templateB ? templateB.name : '';

      const scheduleNumberA = getScheduleNumber(nameA);
      const scheduleNumberB = getScheduleNumber(nameB);

      if (scheduleNumberA !== null && scheduleNumberB !== null) {
          return scheduleNumberA - scheduleNumberB;
      }
      if (scheduleNumberA !== null) {
          return -1;
      }
      if (scheduleNumberB !== null) {
          return 1;
      }
      return nameA.localeCompare(nameB);
    };

    return departmentReports.sort(sortFunction);
  };

  const sortedDepartments = sortReportsByTemplateName(filterReports('department'));

  return (
    <ServiceSidebar
      serviceMenu={ (
        <ServiceMenu
          service={ service }
          serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.REPORT_SERVICE }
        />
      ) }
    >
      <ServiceSidebar.Item
        active={ category === 'basic' }
        href={ basicReportsPath(business.id) }
        icon={ <ReportBasicIcon /> }
        isHidden={
          service.userRole.role !== window.Docyt.Common.Constants.REPORT_SERVICE_ROLE.BOOK_KEEPER
        }
        title="Basic"
      >
        {
          filterReports('basic').length > 0 && filterReports('basic').map((report) => (
            <ServiceSidebar.SubItem
              key={ report.id }
              href={ viewReportPath(business.id, report.slug) }
              title={ report.name }
            />
          ))
        }
      </ServiceSidebar.Item>
      <ServiceSidebar.Item
        active={ category === 'revenue' }
        href={ revenueReportsPath(business.id) }
        icon={ <ReportRevenueIcon /> }
        isHidden={
          business.standardCategoryId !== standardCategoryIds.HOSPITALITY_ID
          && business.standardCategoryId !== standardCategoryIds.UPS_ID
          && business.standardCategoryId !== standardCategoryIds.QSR_ID
        }
        title="Revenue"
      >
        {
          filterReports('revenue').length > 0 && filterReports('revenue').map((report) => (
            <ServiceSidebar.SubItem
              key={ report.id }
              href={ viewReportPath(business.id, report.slug) }
              title={ report.name }
            />
          ))
        }
      </ServiceSidebar.Item>
      <ServiceSidebar.Item
        active={ category === 'expense' }
        href={ expenseReportsPath(business.id) }
        icon={ <ReportExpenseIcon /> }
        title="Expense"
      >
        {
          filterReports('expense').length > 0 && filterReports('expense').map((report) => (
            <ServiceSidebar.SubItem
              key={ report.id }
              href={ viewReportPath(business.id, report.slug) }
              title={ report.name }
            />
          ))
        }
      </ServiceSidebar.Item>
      <ServiceSidebar.Item
        active={ category === 'labor' }
        href={ laborReportsPath(business.id) }
        icon={ <ReportLaborIcon /> }
        isHidden={
          business.standardCategoryId !== standardCategoryIds.HOSPITALITY_ID || !featureFlagforLabor
        }
        title="Labor"
      >
        {
          filterReports('labor').length > 0 && filterReports('labor').map((report) => (
            <ServiceSidebar.SubItem
              key={ report.id }
              href={ viewReportPath(business.id, report.slug) }
              title={ report.name }
            />
          ))
        }
      </ServiceSidebar.Item>
      <ServiceSidebar.Item
        active={ category === 'management' }
        href={ managementReportsPath(business.id) }
        icon={ <ReportManagementIcon /> }
        isHidden={
          business.standardCategoryId !== standardCategoryIds.HOSPITALITY_ID
          && business.standardCategoryId !== standardCategoryIds.UPS_ID
          && business.standardCategoryId !== standardCategoryIds.QSR_ID
          && business.standardCategoryId !== standardCategoryIds.SAAS_ID
          && business.standardCategoryId !== standardCategoryIds.OTHER_ID
        }
        title="Management Reports"
      >
        {
          filterReports('management').length > 0 && filterReports('management').map((report) => (
            <ServiceSidebar.SubItem
              key={ report.id }
              href={ viewReportPath(business.id, report.slug) }
              title={ report.name }
            />
          ))
        }
      </ServiceSidebar.Item>
      <ServiceSidebar.Item
        href={ budgetsPath(business.id) }
        icon={ <ReportBudgetIcon /> }
        launchDarklyFeature="budgets-enabled"
        title="Budget and Forecast"
      />
      <ServiceSidebar.Item
        active={ category === 'department' }
        href={ departmentReportsPath(business.id) }
        icon={ <ReportDepartmentIcon /> }
        isHidden={
          business.standardCategoryId !== standardCategoryIds.HOSPITALITY_ID
          && business.standardCategoryId !== standardCategoryIds.SAAS_ID
        }
        title="Departmental Reports"
      >
        {
          filterReports('department').length > 0 && sortedDepartments.map((report) => (
            <ServiceSidebar.SubItem
              key={ report.id }
              href={ viewReportPath(business.id, report.slug) }
              title={ report.name }
            />
          ))
        }
      </ServiceSidebar.Item>
    </ServiceSidebar>
  );
};

export default Sidemenu;
