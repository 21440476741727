import React, { useMemo } from 'react';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetMetrics } from '@src/hooks/queries/metrics';
import { useURLParams } from '@src/hooks/url_params';
import { TID } from '@src/types/common';
import { TMetricSortColumn } from '@src/types/metrics';
import { ISortingParams } from '@src/types/sorting';

import { ItemsSelectorProvider } from '@src/components/utils/items_selector';

import Filter, { IMetricsFilter } from './filter';
import Header from './header';
import List from './list_item/list';

import styles from '../styles.module.scss';

interface IMetricsServiceDetailsProps {
  businessId: TID,
  isIndustryMetrics: boolean,
}

const DefaultSorting: ISortingParams<TMetricSortColumn> = {
  orderColumn:    'name',
  orderDirection: 'asc',
};

const getBusinessMetricsParamsFromFilter = (
  filterData: IMetricsFilter | undefined,
): IMetricsFilter | undefined => {
  if (!filterData || isEmpty(filterData)) return undefined;
  const filterParams = omit(filterData || {});

  return filterParams;
};

const MetricsServiceDetails = ({
  isIndustryMetrics,
  businessId,
}:IMetricsServiceDetailsProps): JSX.Element => {
  const business = useBusinessContext();

  const { filter, sorting } = useURLParams<
  IMetricsFilter,
  TMetricSortColumn
  >({
    businessId,
    section:        window.Docyt.Common.Constants.METRIC_TYPES.BUSINESS_METRIC,
    defaultSorting: DefaultSorting,
  });

  const filterParams = useMemo(() => {
    return getBusinessMetricsParamsFromFilter(filter.data);
  }, [filter.data]);

  const metricsQuery = useGetMetrics({
    isIndustryMetrics,
    businessId:         business.id,
    standardIndustryId: business.standardCategoryId,
    filters:            filterParams,
    ...sorting.data,
  });

  const metrics = useMemo(() => {
    const metricPages = metricsQuery.data?.pages || [];
    return flatten(metricPages.map((p) => p.collection));
  }, [metricsQuery]);

  const metricIds = useMemo(() => {
    return flatten(metrics).map((d) => d.id);
  }, [metrics]);

  return (
    <ItemsSelectorProvider allItems={ metricIds }>
      <Header
        isIndustryMetrics={ isIndustryMetrics }
      />
      <div className={ styles['metric-list-table-wrapper'] }>
        <Filter
          filter={ filter }
        />
        <List
          businessId={ businessId }
          industryMetrics={ metrics }
          query={ metricsQuery }
          sorting={ sorting }
        />
      </div>
    </ItemsSelectorProvider>
  );
};

export {
  IMetricsServiceDetailsProps,
  MetricsServiceDetails as default,
};
