module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="servicemenu-region"></div>\n<ul class="nav nav-sidebar">\n  ');
    
      if (this.isAdmin) {
        __out.push('\n  <li class="');
        __out.push(__sanitize(this.tab === 'summary' ? 'active' : ''));
        __out.push('">\n    <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('">\n      <span class="icon-money-exchange service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n      <span class="nav-item-text">Summary</span>\n    </a>\n  </li>\n  ');
        if (!this.bankStatementsDocRequestEnabled) {
          __out.push('\n    <li class="');
          __out.push(__sanitize(this.flagEnabled ? '' : 'hidden'));
          __out.push('">\n      <a class="pointer bank-statements-toggle">\n        <span class="icon-bank-statement service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n        <span class="nav-item-text">Bank Statements</span>\n        <i class="fa ');
          __out.push(__sanitize(this.statementsVisible ? 'fa-caret-up' : 'fa-caret-down'));
          __out.push(' font-16"></i>\n      </a>\n    </li>\n    <li class="');
          __out.push(__sanitize(this.tab === 'pending_review' ? 'active' : ''));
          __out.push(' ');
          __out.push(__sanitize(this.flagEnabled ? this.menuHiddenClass : 'hidden'));
          __out.push('">\n      <a href="');
          __out.push(__sanitize(this.homeURL));
          __out.push('/pending_review" class="type-nav-item">\n        <span class="icon-statement-pending-review"></span>\n        <span class="nav-item-text p-l-4 p-t-5 p-b-5">Pending Review</span>\n      </a>\n    </li>\n    <li class="');
          __out.push(__sanitize(this.tab === 'reviewed' ? 'active' : ''));
          __out.push(' ');
          __out.push(__sanitize(this.flagEnabled ? this.menuHiddenClass : 'hidden'));
          __out.push('">\n      <a href="');
          __out.push(__sanitize(this.homeURL));
          __out.push('/reviewed" class="type-nav-item">\n        <span class="icon-statement-reviewed"></span>\n        <span class="nav-item-text p-l-4 p-t-5 p-b-5">Reviewed</span>\n      </a>\n    </li>\n  ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'transactions' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/transactions">\n      <span class="icon-grid-list-blue in-blue-700 service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n      <span class="nav-item-text">Transactions</span>\n      <span class="nav-item-number">');
    
      __out.push(__sanitize(this.transactionsCount));
    
      __out.push('</span>\n    </a>\n  </li>\n  ');
    
      if (this.isAdmin) {
        __out.push('\n  <li class="');
        __out.push(__sanitize(this.tab === 'accounts' ? 'active' : ''));
        __out.push('">\n    <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/accounts">\n      <span class="icon-credit-card-pink service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n      <span class="nav-item-text">Accounts</span>\n      ');
        if (this.plaidErrorsCount > 0) {
          __out.push('\n      <span class="font-10 font-semi-bold badge progress-bar-danger m-r-8">');
          __out.push(__sanitize(this.plaidErrorsCount));
          __out.push(' Issue');
          __out.push(__sanitize(this.plaidErrorsCount > 1 ? 's' : void 0));
          __out.push('</span>\n      ');
        }
        __out.push('\n      <span class="nav-item-number">');
        __out.push(__sanitize(this.accountsCount));
        __out.push('</span>\n    </a>\n  </li>\n  <li class="');
        __out.push(__sanitize(this.tab === 'transaction_imports' ? 'active' : ''));
        __out.push('">\n    <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/transaction_imports">\n      <span class="icon icon-csv-import-purple service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n      <span class="nav-item-text">CSV Import</span>\n      ');
        if (this.csvImportsCount > 0) {
          __out.push('\n      <span class="nav-item-number">');
          __out.push(__sanitize(this.csvImportsCount));
          __out.push('</span>\n      ');
        }
        __out.push('\n    </a>\n  </li>\n  <li class="');
        __out.push(__sanitize(this.tab === 'trash' ? 'active' : ''));
        __out.push('">\n    <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/trash">\n      <span class="icon icon-banking-account-trash service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n      <span class="nav-item-text">Trash</span>\n      <span class="nav-item-number">');
        __out.push(__sanitize(this.deletedTransactionsCount));
        __out.push('</span>\n    </a>\n  </li>\n  ');
      }
    
      __out.push('\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}