import React, { FC } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { rcBankTransfersPath } from '@src/routes';
import { IBankAccountReconciliationItem } from '@src/types/bank_account_reconciliations';

import DownloadIcon from '@src/components/reconciliation_center/month_end/download_action/download_icon';
import FooterContainer from '@src/components/reconciliation_center/month_end/drawers/footer_container';
import inDrawer from '@src/components/reconciliation_center/month_end/drawers/in_drawer';
import TotalAmount from '@src/components/reconciliation_center/month_end/drawers/total_amount';
import {
  useTransfersCollection,
} from '@src/components/reconciliation_center/month_end/drawers/unmatched_transfers/hooks';
import { AppLink } from '@src/components/ui_v2/buttons';
import TableSection from '@src/components/ui_v2/table_section';
import { MoreInfoIcon } from '@src/components/utils/icomoon';
import { useSection } from '@src/components/utils_v2/section';

import Table from './table';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

const UnmatchedTransfers: FC<{ reconciliationItem: IBankAccountReconciliationItem }> = ({
  reconciliationItem,
}) => {
  const business = useBusinessContext();
  const section = useSection();
  const queryParams = {
    filter: {
      all_state:              'true',
      reconciliation_item_id: reconciliationItem.id,
    },
  };

  const collection = useTransfersCollection({
    businessId: business.id,
    params:     queryParams,
    section,
  });

  const pages = collection.query.data?.pages || [];
  const totalAmount = pages[0]?.meta?.totalAmount;

  return (
    <>
      <div className={ styles.content }>
        <TableSection>
          <Table
            infiniteQuery={ collection.query }
            records={ collection.records }
            sorting={ collection.sorting }
          />
        </TableSection>
      </div>
      <FooterContainer>
        <TotalAmount amount={ totalAmount } />
      </FooterContainer>
    </>
  );
};

const Controls: FC<{ reconciliationItem: IBankAccountReconciliationItem }> = ({
  reconciliationItem,
}) => {
  const business = useBusinessContext();
  const unmatchedTransfersPathStr = `${rcBankTransfersPath(business.id)}?reconciliation_item_id=${reconciliationItem.id}`;

  const queryParams = { filter: { reconciliation_item_id: reconciliationItem.id } };

  return (
    <>
      <AppLink newWindow href={ unmatchedTransfersPathStr }>
        <MoreInfoIcon fontSize={ 18 } inColor="black" />
        { ' ' }
        Full View
      </AppLink>
      <DownloadIcon
        exportParams={ queryParams as object }
        fontSize={ 18 }
        inColor="black"
        type="unmatched_transfers"
      />
    </>
  );
};

export default inDrawer(UnmatchedTransfers, Controls, {
  title:       'Unmatched Transfers',
  sectionName: 'month-end-unmatched-transfers',
});
