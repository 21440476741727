import { useEffect, useState } from 'react';

import { useQuilttConnector, ConnectorSDKEventType, useQuilttSession } from '@quiltt/react';

import { IQuilltMeta } from "../types/financial_institution_connection"

interface IQuilltProps {
	handleOnSuccessCallback(metadata: IQuilltMeta): void,
	handleOnFailureCallback?(errorType: string): void
}

export default function useQuiltt({
  handleOnFailureCallback, handleOnSuccessCallback,
}: IQuilltProps) {
  const { importSession, revokeSession } = useQuilttSession();
  const [reConnectInstitution, setReConnectInstitution] = useState<{connectionId:string, intitution?:string} | undefined>();

  const { open } = useQuilttConnector(window.configData?.quiltt?.default_connector_id, {
    onExitSuccess: (metadata) => {
      handleOnSuccessCallback({
        ...metadata,
        type: 'quiltt',
      });
    },
    onExit: (errorType: ConnectorSDKEventType) => {
      if (errorType !== 'exited.successful') {
        if (handleOnFailureCallback) {
          handleOnFailureCallback('Something went wrong');
        }
      }
      revokeSession();
    },
    connectionId: reConnectInstitution?.connectionId ?? '',
    institution:  reConnectInstitution?.intitution,
  });

  useEffect(() => {
    if (reConnectInstitution?.connectionId) {
      open();
    }
  }, [reConnectInstitution, open]);

  return {
    open,
    setReConnectInstitution,
    importQuilttSession: importSession,
  };
}
