import { TID } from '@src/types/common';
import { IRevenueReportType } from '@src/types/revenue_report_types';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetRevenueReportTypesParams {
  businessId: TID,
  onlyActive?: boolean,
}

interface IGetRevenueReportTypesResponse {
  collection: IRevenueReportType[],
}

const getRevenueReportTypes = (
  params: IGetRevenueReportTypesParams,
): Promise<IGetRevenueReportTypesResponse> => {
  return apiGet(
    '/api/v1/revenue_report_types',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      collection: cdata.revenueReportTypes as IRevenueReportType[],
    };
  });
};

export {
  IGetRevenueReportTypesParams,
  IGetRevenueReportTypesResponse,
  getRevenueReportTypes,
};
