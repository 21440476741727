/* eslint-disable eqeqeq */
import React from 'react';

import { transactionsPath, documentRequestsPath, flaggedTransactionsPath, businessInboxPath, vaultTrashPath, accountsPath, CSVImportPath, bankingAccountsTrashPath, bankConnectionCenterPath, settingsIntegrationExternalSystemsPath, rcSettingsPath, businessSettingsPath, rcLedgerSyncingCentrePath, rcChartOfAccountsPath, businessSettingsStepPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';
import ServiceSubMenu from '../module_header_sub_menu';

import styles from '../../navigation_styles.module.scss';

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  info: IBusinessServicesInfo,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

const BusinessItem = ({
  businessId,
  info,
  activeItem,
  focusToMyClient,
  isGlobalNavigationEnabled
}: IServiceMenuProps) => {
  const canVisibleInboxService = info.isBusinessAdmin || info.canAccessInboxService;
  const isOpenItemVisible = info?.isAccountingFirmUser || info?.hasFlaggedTransactionsAdvisorRole || info?.documentRequestServiceRole;

  const generalSettingsItems: { step: number; title: string }[] = [
    { step: 1, title: "Management Group" },
    { step: 2, title: "Accounting Software" },
    { step: 3, title: "Business Profile" },
    { step: 4, title: "Business Employees" },
    { step: 5, title: "Financial Accounts" },
    { step: 6, title: "Payment Methods" },
    { step: 7, title: "Billing Information" },
    { step: 8, title: "Document Collection" },
    { step: 9, title: "Notification Settings" },
  ];  

  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.BUSINESS }
      focusToMyClient={ focusToMyClient }
      isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
      title={ window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.BUSINESS }
    >
      <li className='p-t-8'>
        <span className={ `m-l-18 m-b-8 ${styles['dropdown-item-title']}` }>
          { window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.BUSINESS.toUpperCase() }
        </span>
      </li>
      <ServiceItem
        serviceUrl=''
        title={ window.Docyt.Common.Constants.SERVICE_NAMES.DASHBOARD }
      />
      {isOpenItemVisible && (
        <ServiceSubMenu
          hasBorderBottom
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.OPEN_ITEMS }
        >
          {info?.documentRequestServiceRole && (
            <ServiceItem
              serviceUrl={ documentRequestsPath(businessId) }
              title="Document Request"
            />
          )}
          {(!!info?.isAccountingFirmUser && info?.hasFlaggedTransactionsAdvisorRole) && (
            <ServiceItem
              serviceUrl={ flaggedTransactionsPath(businessId) }
              title="Flagged Transactions"
            />
          )}
        </ServiceSubMenu>
      )}
      {canVisibleInboxService && (
        <ServiceSubMenu
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.VAULT }
        >
          <ServiceItem
            serviceUrl={ businessInboxPath(businessId) }
            title="Inbox"
          />
          <ServiceItem
            serviceUrl={ vaultTrashPath(businessId) }
            title="Trash"
          />
        </ServiceSubMenu>
      )}
      <ServiceSubMenu
        title={ window.Docyt.Common.Constants.SERVICE_NAMES.FINANCIAL_ACCOUNTS }
      >
        {(info.isBankingServiceAdminUser) && (
          <ServiceItem
            serviceUrl={ accountsPath(businessId) }
            title="All Accounts"
          />
        )}
        <ServiceItem
          hasBorderBottom
          serviceUrl={ transactionsPath(businessId) }
          title="All Transactions"
        />
        {(info.isBankingServiceAdminUser) && (
          <>
            <ServiceItem
              serviceUrl={ CSVImportPath(businessId) }
              title="CSV Import"
            />
            <ServiceItem
              hasBorderBottom
              serviceUrl={ bankingAccountsTrashPath(businessId) }
              title="Trash"
            />
          </>
        )}
        <ServiceItem
          serviceUrl={ bankConnectionCenterPath(businessId) }
          title="Bank Connection Center"
        />
      </ServiceSubMenu>
      <ServiceSubMenu
        hasBorderBottom
        title={ window.Docyt.Common.Constants.SERVICE_NAMES.ACCOUNTING }
      >
        <ServiceItem
          serviceUrl={
            info.reconciliationCenterReady
              ? rcChartOfAccountsPath(businessId)
              : businessSettingsPath(businessId)
          }
          title="Chart Of Accounts"
        />
        <ServiceItem
          serviceUrl=''
          title="Departments"
        />
        <ServiceItem
          hasBorderBottom
          serviceUrl={
            info.reconciliationCenterReady
              ? rcLedgerSyncingCentrePath(businessId)
              : businessSettingsPath(businessId)
          }
          title="General Ledger Sync Center"
        />
        <ServiceItem
          serviceUrl={
            info.reconciliationCenterReady
              ? rcSettingsPath(businessId)
              : businessSettingsPath(businessId)
          }
          title="Docyt AI Settings"
        />
      </ServiceSubMenu>
      <ServiceItem
        serviceUrl={ settingsIntegrationExternalSystemsPath(businessId) }
        title={ window.Docyt.Common.Constants.SERVICE_NAMES.DATA_INTEGRATIONS }
      />
      <ServiceSubMenu title={ window.Docyt.Common.Constants.SERVICE_NAMES.GENERAL_SETTINGS }>
        {generalSettingsItems.map((item) => (
          <ServiceItem
            key={ item.step }
            hasBorderBottom={ [2, 4, 8].includes(item.step) }
            serviceUrl={ businessSettingsStepPath(businessId, item.step) }
            title={ item.title }
          />
        ))}
      </ServiceSubMenu>
    </ModuleHeaderMenu>
  );
};

export default BusinessItem;
