import React, { useCallback, useState } from 'react';

import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';

import { Button } from '@src/components/ui/buttons';
import Table from '@src/components/ui/table/table';
import { SuccessIcon } from '@src/components/utils/icomoon';

import MicroDepositItem from './micro_deposit_item';

import styles from './styles.module.scss';

interface IMicroDepositListProps {
  invitation: ISelfOnboardingInvitation,
  isSuccess: boolean,
}

const MicroDepositList = ({
  invitation,
  isSuccess,
}: IMicroDepositListProps) => {
  const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(isSuccess);

  const handleSubmit = useCallback(() => {
    Backbone.history.navigate('/self_onboarding?step=4&success=1', { trigger: false, replace: true });
    setShowSuccessBanner(true);
  }, [setShowSuccessBanner]);

  if (showSuccessBanner) {
    return (
      <div className={ styles['deposit-success'] }>
        <SuccessIcon fontSize={ 80 } mt={ 80 } />
        <h2 className="font-18 in-black m-t-30">Great, successfully saved the changes.</h2>
        <span className="m-t-10">Our onboarding expert will get in touch with you shortly.</span>
      </div>
    );
  }

  return (
    <div className={ styles['self-onboarding-deposit-container'] }>
      <div className="tasks-container">
        <h2>Verify Operating Bank Account</h2>
        <p className="font-11 in-grey-1050">
          Check your below account(s) for micro-deposits from Docyt.
          Once verified, you&apos;re all set for seamless ACH and check payments at Docyt.
        </p>
        <div className="tasks-view">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HCell widthPercent={ 18 }>Business Name</Table.HCell>
                <Table.HCell widthPercent={ 30 }>Operating Bank Account</Table.HCell>
                <Table.HCell widthPercent={ 12 }>Last4</Table.HCell>
                <Table.HCell widthPercent={ 15 }>Deposit 1</Table.HCell>
                <Table.HCell widthPercent={ 15 }>Deposit 2</Table.HCell>
                <Table.HCell />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {
              invitation.businesses.map((business) => (
                <MicroDepositItem key={ business.id } business={ business } />
              ))
            }
            </Table.Body>
          </Table>
        </div>
      </div>
      <div className="setup-client-footer">
        <Button
          className="pull-right bg-purple-1000 in-white width-180px"
          data-color="$purple-1000"
          onClick={ handleSubmit }
        >
          Save and Submit
        </Button>
      </div>
    </div>
  );
};

export default MicroDepositList;
