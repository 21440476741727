import React from 'react';

import { IBusinessUser } from '@src/types/business_users';
import { IReport, TReportCategory } from '@src/types/report_service/report';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import DeleteReportAction from './delete_report_action';
import EditAccessAction from './edit_access_action';
import EditMappingAction from './edit_mapping_action';
import RenameReportAction from './rename_report_action';
import UpdateReportAction from './update_report_action';

interface IReportItemActionsProps {
  report: IReport,
  category: TReportCategory,
  users: IBusinessUser[],
}

const ReportItemActions = ({
  report,
  category,
  users,
}: IReportItemActionsProps): JSX.Element => {
  return (
    <ActionsDropdown className="m-l-auto m-r-15 m-t-5">
      <RenameReportAction report={ report } />
      <EditAccessAction category={ category } report={ report } users={ users } />
      <EditMappingAction category={ category } report={ report } />
      <UpdateReportAction report={ report } />
      <DeleteReportAction category={ category } report={ report } />
    </ActionsDropdown>
  );
};

export default ReportItemActions;
