import { useMutation, useQuery } from 'react-query';

import {
  getBusinessVendor,
  uploadVendorProfileLogo,
  IBusinessVendorParams,
  IGetBusinessVendorParams,
  updateBusinessVendor,
  syncBusinessVendorWithQbo,
  IQboVendorsResponse,
  getQboVendors,
} from '@src/requests/business_vendors';
import {
  getVendor,
  getVendorBusinessEmailVerified,
  getReceivableFundingSource,
  IMergeToGlobalVendorParams,
  INoMergeToGlobalVendorParams,
  INoMergeToGlobalVendorResponse,
  IVendorBusinessEmailVerifiedResponse,
  postNoMergeToGlobalVendor,
  putMergeToGlobalVendor,
  IArchiveVendor,
  IUnArchiveVendor,
  IRemoveVendor,
  putArchiveVendor,
  putUnarchiveVendor,
  deleteFromVendor,
  IRemoveAchBankParams,
  postRemoveAchBank,
} from '@src/requests/vendors';
import { IBusinessVendor, IVendor } from '@src/types/business_vendors';
import { IDwollaFundingSource } from '@src/types/dwolla_funding_sources';

const VENDOR = 'vendor';
const BUSINESS_VENDOR = 'business_vendor';

const useGetVendor = (id: number) => {
  return useQuery<IVendor, Error>(
    [VENDOR, id],
    () => getVendor(id),
  );
};

const useMergeToGlobalVendor = () => {
  return useMutation<void, Error, IMergeToGlobalVendorParams>(putMergeToGlobalVendor);
};

const useNoMergeToGlobalVendor = () => {
  return useMutation<INoMergeToGlobalVendorResponse, Error,
    INoMergeToGlobalVendorParams>(postNoMergeToGlobalVendor);
};

const useGetBusinessVendor = (params: IGetBusinessVendorParams) => {
  return useQuery<IBusinessVendor, Error>(
    [BUSINESS_VENDOR, params],
    () => getBusinessVendor(params),
    {
      enabled: Boolean(params.vendorId) && Boolean(params.businessId),
    },
  );
};

export const useUpdateBusinessVendor = () => {
  return useMutation<IBusinessVendor, Error, IBusinessVendorParams>(
    updateBusinessVendor,
  );
};

export const useUploadVendorProfileLogo = (file: File) => {
  return useQuery<string, Error>(
    ['vendor_logo_generate_key'],
    () => uploadVendorProfileLogo(file),
    {
      enabled: !!file,
    },
  );
};

export const useSyncBusinessVendorWithQbo = () => {
  return useMutation<void, Error, { vendorId: number, businessId: number }>(
    syncBusinessVendorWithQbo,
  );
};

export const useGetQboVendors = (params: { businessId: number, search: string }) => {
  return useQuery<IQboVendorsResponse[], Error>(
    ['qbo_vendors_search'],
    () => getQboVendors(params),
    {
      enabled: Boolean(params.businessId),
    },
  );
};

const useGetVendorBusinessEmailVerified = (id: number) => {
  return useQuery<IVendorBusinessEmailVerifiedResponse, Error>(
    ['vendor_business_email_verified', id],
    () => getVendorBusinessEmailVerified(id),
  );
};

const useGetReceivableFundingSource = (id: number) => {
  return useQuery<IDwollaFundingSource, Error>(
    ['receivable_funding_source', id],
    () => getReceivableFundingSource(id),
  );
};

const useArchiveVendor = () => {
  return useMutation<void, Error, IArchiveVendor>(
    putArchiveVendor,
  );
};

const useUnarchiveVendor = () => {
  return useMutation<void, Error, IUnArchiveVendor>(
    putUnarchiveVendor,
  );
};

const useRemoveVendor = () => {
  return useMutation<void, Error, IRemoveVendor>(
    deleteFromVendor,
  );
};

const useRemoveBankAccount = () => {
  return useMutation<void, Error, IRemoveAchBankParams>(
    postRemoveAchBank,
  );
};

export {
  useGetVendor,
  useMergeToGlobalVendor,
  useNoMergeToGlobalVendor,
  useGetBusinessVendor,
  useGetVendorBusinessEmailVerified,
  useGetReceivableFundingSource,
  useArchiveVendor,
  useUnarchiveVendor,
  useRemoveVendor,
  useRemoveBankAccount,
};
