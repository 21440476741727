import React, { useCallback, useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetBusinessAllUsers } from '@src/hooks/queries/business_users';
import { viewReportPath } from '@src/routes';
import { IReport, ITemplate, TReportCategory } from '@src/types/report_service/report';

import { UserAvatarsList } from '@src/components/ui/avatars';
import Tooltip from '@src/components/ui_v2/tooltip';
import { UnableToSyncIcon } from '@src/components/utils/icomoon/';

import ReportItemActions from '../actions/report_item_actions';

import styles from '../styles.module.scss';

interface IReportItemProps {
  report: IReport,
  templates: ITemplate[],
  category: TReportCategory,
}

const ReportItem = ({
  report,
  templates,
  category,
}: IReportItemProps): JSX.Element => {
  const business = useBusinessContext();
  const usersQuery = useGetBusinessAllUsers({
    businessId:                business.id,
    serviceProviderBusinessId: business.serviceProviderBusiness?.id || null,
  });
  const users = useMemo(() => (
    (usersQuery.data?.businessUsers || []).filter((user) => (
      report.accessibleUserIds.includes(user.id)
    ))
  ), [report.accessibleUserIds, usersQuery.data?.businessUsers]);
  const template = useMemo(() => (
    templates.filter((tem) => tem.id === report.templateId)[0]
  ), [report.templateId, templates]);

  const handleClick = useCallback(() => {
    Backbone.history.navigate(
      viewReportPath(business.id, report.slug),
      { trigger: true },
    );
  }, [business.id, report.slug]);

  return (
    <div className={ `${styles['report-item-container']} font-16` }>
      <div
        className="col-xs-4 height-100-percent display-flex align-items-center pointer p-l-20"
        role="navigation"
        onClick={ handleClick }
      >
        { report.name }
        { report.itemAccountsCount === 0 && !report.editMappingDisabled && (
          <Tooltip.Hover content={ window.Docyt.Common.Constants.Messages.CUSTOM_REPORT_NO_MAPPED }>
            <UnableToSyncIcon className="m-l-20 in-red-600" />
          </Tooltip.Hover>
        )}
      </div>
      <div
        className="col-xs-3 height-100-percent display-flex align-items-center pointer"
        role="navigation"
        onClick={ handleClick }
      >
        <UserAvatarsList users={ users } />
      </div>
      <div
        className="col-xs-4 height-100-percent display-flex align-items-center pointer"
        role="navigation"
        onClick={ handleClick }
      >
        { template.name }
      </div>
      <ReportItemActions
        category={ category }
        report={ report }
        users={ users }
      />
    </div>
  );
};

export default ReportItem;
