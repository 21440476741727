import React from 'react';

import { IBusiness } from '@src/types/businesses';
import { IReport } from '@src/types/report_service/report';
import { IReportItemAccountValue } from '@src/types/report_service/report_item_value_account';

import Table from '@src/components/ui/table';

import Item from './list_item/item';

interface IMultiEntityItemAccountValueListProps {
  multiBusinessReportId: string,
  businesses: IBusiness[],
  reports: IReport[],
  itemIdentifier: string,
  aggregatedItemAccountValues: IReportItemAccountValue[],
  businessItemAccountValues: IReportItemAccountValue[],
  from: string,
  to: string,
}

const MultiEntityItemAccountValueList = ({
  businesses,
  reports,
  itemIdentifier,
  aggregatedItemAccountValues,
  businessItemAccountValues,
  from,
  to,
  multiBusinessReportId,
}: IMultiEntityItemAccountValueListProps): JSX.Element => {
  return (
    <Table className="multi-entity-item-values-table">
      <Table.Head>
        <Table.Row>
          <Table.HCell aria-label="Chart Of Account">Chart Of Account</Table.HCell>
          <Table.HCell textAlign="center">Total</Table.HCell>
          {
          businesses.map((business) => {
            return (
              <Table.HCell
                key={ business.id }
                textAlign="center"
              >
                { business.displayName }
              </Table.HCell>
            );
          })
        }
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          aggregatedItemAccountValues.map((aggregatedItemAccountValue) => {
            return (
              <Item
                key={ aggregatedItemAccountValue.id }
                businessItemAccountValues={ businessItemAccountValues }
                from={ from }
                itemIdentifier={ itemIdentifier }
                multiBusinessReportId={ multiBusinessReportId }
                reports={ reports }
                to={ to }
                value={ aggregatedItemAccountValue }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default MultiEntityItemAccountValueList;
