import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IGetBankFeedTransactionServiceDocumentsResponse } from '@src/requests/bank_feed_transaction_service_documents';
import {
  IGetTransactionServiceDocumentsParams,
  IGetTransactionServiceDocumentsResponse,
  getTransactionServiceDocuments,
  IDeleteServiceTransactionDocumentParams,
  IMoveToTrashTransactionServiceDocumentParams,
  IAddTransactionServiceDocumentParams,
  IResolveDuplicateParams,
  deleteServiceTransactionDocument,
  moveToTrashTransactionServiceDocument,
  addTransactionServiceDocument,
  resolveDuplicateTransactionServiceDocument,
  IVerifyTransactionServiceDocumentParams,
  verifyTransactionServiceDocument,
  IVerifyTransactionServiceDocumentResponse,
  ISetBankStatementParams,
  setTransactionServiceDocumentBankStatement,
  ISetTransactionServiceDocumentBankStatementResponse,
  IGetTransfersParams,
  getTransfers,
  updateTsdFlaggedState,
  IUpdateTsdFlaggedStateResponse,
} from '@src/requests/transaction_service_documents';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { IBulkMutationParams, makeBulkRequest, IBulkMutationReturn } from './bulk_mutations';
import {
  createUseGetInfiniteCollection, updateItemsInInfiniteCollection,
} from './infinite_collection_queries';

const useGetTransactionServiceDocuments = createUseGetInfiniteCollection<
  ITransactionServiceDocument,
  IGetTransactionServiceDocumentsParams,
  IGetTransactionServiceDocumentsResponse
>({
  queryKey: QueryKey.transactionServiceDocumentsQuery,
  request:  getTransactionServiceDocuments,
});

const useGetSubTransactionServiceDocuments = createUseGetInfiniteCollection<
  ITransactionServiceDocument,
  IGetTransactionServiceDocumentsParams,
  IGetTransactionServiceDocumentsResponse
  >({
    queryKey: QueryKey.subTransactionServiceDocumentsQuery,
    request:  getTransactionServiceDocuments,
  });

const useGetTransfers = createUseGetInfiniteCollection<
  ITransactionServiceDocument,
  IGetTransfersParams,
  IGetTransactionServiceDocumentsResponse
>({
  queryKey: QueryKey.transfersQuery,
  request:  getTransfers,
});

const useDestroyTransactionServiceDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteServiceTransactionDocumentParams>(
    deleteServiceTransactionDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
        queryClient.invalidateQueries(QueryKey.bankFeedTransactionServiceDocument);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    },
  );
};

const useMoveToTrashTransactionServiceDocument = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, IMoveToTrashTransactionServiceDocumentParams>(
    moveToTrashTransactionServiceDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
        queryClient.invalidateQueries(QueryKey.bankFeedTransactionServiceDocument);
        queryClient.invalidateQueries(QueryKey.bankFeedInformation);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    },
  );
};

const useBulkAddTransactionServiceDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<IAddTransactionServiceDocumentParams>
  >(
    makeBulkRequest(addTransactionServiceDocument),
    {
      onSettled: () => {
        return queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery)
          .then(() => {
            queryClient.invalidateQueries(QueryKey.bankFeedTransactionServiceDocument);
            window.Docyt.vent.trigger('banking_accounts:information:updated');
          });
      },
    },
  );
};

const useBulkResolveDuplicates = () => {
  const queryClient = useQueryClient();

  return useMutation<IBulkMutationReturn, Error, IBulkMutationParams<IResolveDuplicateParams>>(
    makeBulkRequest(resolveDuplicateTransactionServiceDocument),
    {
      onSettled: () => {
        return queryClient.invalidateQueries(QueryKey.subTransactionServiceDocumentsQuery)
          .then(() => window.Docyt.vent.trigger('banking_accounts:information:updated'));
      },
    },
  );
};

const useBulkVerifyTransactionServiceDocuments = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn<IVerifyTransactionServiceDocumentResponse>,
    Error,
    IBulkMutationParams<
      IVerifyTransactionServiceDocumentParams,
      IVerifyTransactionServiceDocumentResponse
    >
  >(
    makeBulkRequest(verifyTransactionServiceDocument),
    {
      onSettled: () => {
        return queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
      },
    },
  );
};

const useSetTransactionServiceDocumentBankStatement = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ISetTransactionServiceDocumentBankStatementResponse, Error, ISetBankStatementParams>(
      setTransactionServiceDocumentBankStatement,
      {
        onSuccess: (response, params) => {
          queryClient.invalidateQueries(QueryKey.bankFeedInformation);
          queryClient.setQueriesData(
            QueryKey.bankFeedTransactionServiceDocument,
            (
              data?: InfiniteData<IGetBankFeedTransactionServiceDocumentsResponse>,
            ): InfiniteData<IGetBankFeedTransactionServiceDocumentsResponse> => {
              return updateItemsInInfiniteCollection<
              ITransactionServiceDocument,
              IGetBankFeedTransactionServiceDocumentsResponse
            >(
              data,
              [params.id],
              {
                amount:        response.bankFeedTransactionServiceDocument.amount,
                bankStatement: response.bankFeedTransactionServiceDocument.bankStatement,
              },
            );
            },
          );
        },
      },
    );
};

const useUpdateTsdFlaggedState = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IUpdateTsdFlaggedStateResponse, Error, ITransactionServiceDocument>(
      updateTsdFlaggedState,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
        },
      },
    );
};

export {
  useGetTransactionServiceDocuments,
  useGetSubTransactionServiceDocuments,
  useDestroyTransactionServiceDocument,
  useMoveToTrashTransactionServiceDocument,
  useBulkAddTransactionServiceDocument,
  useBulkResolveDuplicates,
  useBulkVerifyTransactionServiceDocuments,
  useSetTransactionServiceDocumentBankStatement,
  useGetTransfers,
  useUpdateTsdFlaggedState,
};
