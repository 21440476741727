import React, { useCallback, useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';

import Sidemenu from '@src/components/revenue_center/sidemenu/sidemenu';
import Alert from "@src/components/ui_v2/alerts/alert";
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import PageContainer from '@src/components/ui_v2/layout/page_container/page_container';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import { InfoIcon } from "@src/components/utils/icomoon";
import Section from '@src/components/utils_v2/section';

import RevenueServiceTabs from '../common/revenue_service_tabs/revenue_service_tabs';
import { useRevenueSystemContext } from '../revenue_system_provider';
import { useRSDCollection } from './hooks';
import Table from './table';

import styles from '../deposit_tracking/styles.module.scss';

const RevenueCapture = (): JSX.Element => {
  const [showResetBanner, setShowResetBanner] = useState<boolean>(false);
  const revenueService = useRevenueServiceContext();
  const revenueSystem = useRevenueSystemContext();
  const business = useBusinessContext();

  const handleOnReset = useCallback(() => {
    if (showResetBanner) return;

    setShowResetBanner(true);
    setTimeout(() => setShowResetBanner(false), 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.Docyt.vent.on('revenue:reset:success', handleOnReset);
    setShowResetBanner(false);

    return () => {
      window.Docyt.vent.off('revenue:reset:success');
    };
  }, [handleOnReset, revenueSystem.id]);

  const collection = useRSDCollection({
    revenueService,
    revenueSystem,
  });

  return (
    <>
      <Outlet />
      <PageContainer sidemenu={ <Sidemenu /> }>
        <DetailsRegion>
          <DetailsRegion.Header
            className={ styles['tab-container'] }
            title={ revenueSystem.name }
          >
            <RevenueServiceTabs
              activeTab="RevenueCapture"
              businessId={ business.id }
              revenueSystemId={ revenueSystem.id }
            />
          </DetailsRegion.Header>

          {
            showResetBanner && (
              <Alert
                icon={ <InfoIcon relative color='white' fontSize={ 18 } mr={ 8 } /> }
                mt={ 10 }
                variant="highlight"
              >
                Reset report in progress. This will take some time
              </Alert>
            )
          }
          <Section.Provider section={ collection.section }>
            <TableSection>
              <Table
                queries={ collection.queries }
                records={ collection.records }
                sorting={ collection.sorting }
              />
            </TableSection>
          </Section.Provider>
        </DetailsRegion>
      </PageContainer>
    </>
  );
};

export default React.memo(RevenueCapture);
