module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="transaction-list-region" class="transaction-list-region">\n  <div>\n    <div class="clearfix transaction-sub-header">\n      <h2 class="font-bold font-26 pull-left">Uncategorized Transactions</h2>\n        <ul class="nav sub-header-menu p-t-5">\n            <li class="m-r-25 ');
    
      __out.push(__sanitize(this.type === 'unverified' ? 'active' : ''));
    
      __out.push('">\n              <a class="queued-invoices-link" href="');
    
      __out.push(__sanitize(this.unverifiedURL));
    
      __out.push('"> UNCATEGORIZED </a>\n            </li>\n            <li class="m-r-25 ');
    
      __out.push(__sanitize(this.type === 'excluded' ? 'active' : ''));
    
      __out.push('">\n              <a class="paid-invoices-link" href="');
    
      __out.push(__sanitize(this.excludedURL));
    
      __out.push('"> EXCLUDED </a>\n            </li>\n        </ul>\n      <div class="reconciliation-option pull-right text-right">\n        <div>\n          <p class="inline-block">Books Lock Date:  <span class="pointer closing-date">');
    
      __out.push(__sanitize(this.formattedClosingDate));
    
      __out.push('</span></p>\n          <div class=\'icon icon-unverified inline-block m-l-10 closing-date-tooltip-js\' data-toggle="tooltip" data-html="true" data-placement="bottom" title="');
    
      __out.push(__sanitize(this.closing_date_tooltip));
    
      __out.push('">\n            <span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span>\n          </div>\n        </div>\n        <div>\n          <p class="inline-block"><span class="icon-bot-notification-icon font-18 m-r-5"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>\n             Auto Pilot Setting: \n          </p>\n          <select id="auto-verification" class="form-control inline-block select-verification-js ');
    
      __out.push(__sanitize(this.autoVerification ? 'on' : 'off'));
    
      __out.push(' m-l-5" placeholder="Type">\n            <option value="off" ');
    
      if (!this.autoVerification) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>OFF</option>\n            <option value="on" ');
    
      if (this.autoVerification) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>ON</option>\n          </select>\n          <div class=\'icon icon-unverified inline-block m-l-10 verification-tooltip-js\' data-toggle="tooltip" data-html="true" data-placement="bottom" title="');
    
      __out.push(__sanitize(this.verification_tooltip));
    
      __out.push('">\n            <span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span>\n          </div>\n        </div>\n      </div>\n    </div>\n    <hr/>\n    <div class="display-flex align-items-center justify-content-between">\n      <div class="display-flex align-items-center">\n        <div class="dropdown">\n          <button class="btn btn-blue-on-hover dropdown-toggle font-14 font-semi-bold p-b-10 p-l-16 p-r-16 p-t-10" type="button" id="bulk-dropdown-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n            Bulk Action\n            <span class="fa fa-caret-down"></span>\n          </button>\n          <div class="dropdown-menu" aria-labelledby="bulk-dropdown-btn">\n            ');
    
      if (this.type === 'excluded') {
        __out.push('\n              <div class="bulk-action-item include-transaction-js">\n                <i class="icon icon-move-item font-20"></i>\n                <span class="font-12 m-l-18"> Move to Uncategorized </span>\n              </div>\n            ');
      } else {
        __out.push('\n              <div class="bulk-action-item review-transaction-js">\n                <i class="icon icon-check_circle_large font-16"></i>\n                <span class="font-12 m-l-18"> Mark as Reviewed </span>\n              </div>\n              <div class="bulk-action-item re-open-transaction-js">\n                <i class="icon icon-re-open font-16"></i>\n                <span class="font-12 m-l-18"> Re-Open Issue </span>\n              </div>\n              <div class="bulk-action-item flag-transaction-js">\n                <i class="icon icon-docyt-support font-16"></i>\n                <span class="font-12 m-l-18"> Flag for Client Review </span>\n              </div>\n              <div class="bulk-action-item close-transaction-js">\n                <i class="icon icon-cross-icon font-16"></i>\n                <span class="font-12 m-l-18"> Close Issue </span>\n              </div>\n              <div class="bulk-action-item exclude-transaction-js">\n                <i class="icon icon-cross-icon font-16"></i>\n                <span class="font-12 m-l-18"> Exclude Transactions </span>\n              </div>\n              <div class="bulk-action-item export-csv-js">\n                <i class="icon icon-download font-16"></i>\n                <span class="font-12 m-l-18"> Export as CSV </span>\n              </div>\n              <div class="bulk-action-item no-required-js">\n                <i class="icon icon-mark-as-matched font-16"></i>\n                <span class="font-12 m-l-18"> No document needed </span>\n              </div>\n              <div class="bulk-action-item match-as-transfer-js p-l-12">\n                <i class="icon icon-bank-transfers-nav font-24"></i>\n                <span class="font-12 m-l-12"> Match as Bank Transfer </span>\n              </div>\n            ');
      }
    
      __out.push('\n          </div>\n        </div>\n        <span class="m-l-16 font-16 inline-block">\n          Click on each transaction and categorize to add them to your accounting ledger.\n        </span>\n      </div>\n      <div class="display-flex align-items-center">\n        <div class="only-flagged checkbox checkbox-primary inline-block m-l-15 m-r-15 m-b-0 m-t-0">\n          <input type="checkbox" class="pointer check-only-flagged" ');
    
      __out.push(__sanitize(this.onlyFlagged ? 'checked' : ''));
    
      __out.push('>\n          <label>Show only flagged transactions</label>\n        </div>\n        <div class="counter-container"></div>\n      </div>\n    </div>\n    <div id="similar-transactions-found-notice" class="display-flex justify-content-between">\n      <div>\n        <i class="icon icon-infocircle"></i>\n        Similar transactions found\n      </div>\n\n      <a class="pointer font-bold select-all-similar-js">Click Here to Select All</a>\n    </div>\n    <div class="table-responsive transactions-table-wrapper">\n      <div id="transaction-table" class="panel panel-default">\n        <div class="transaction-filter-container"></div>\n        <div class="uncategorized-items-table"></div>\n      </div>\n    </div>\n  </div>\n</div>\n<div id="transaction-right-side-region" class="transaction-right-side-region hidden"></div>\n\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}