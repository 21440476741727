import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import BulkActionProgress from '@src/components/common/bulk_action_modal/bulk_action_progress';
import Modal from '@src/components/ui/modal';
import Text from '@src/components/ui/text';

interface IProgressAddModallProps extends IUseModalProps {
  doneCount: number;
  totalCount: number;
}

const ProgressAddModal = ({
  isOpen,
  doneCount,
  totalCount,
}: IProgressAddModallProps): JSX.Element => {
  return (
    <Modal
      show={ isOpen }
      title="Add Billing Informations in Progress"
    >
      <Modal.Body>
        <Text as="div">
          <BulkActionProgress
            doneCount={ doneCount }
            itemsTitle="Billing Informations"
            totalCount={ totalCount }
          />
        </Text>
      </Modal.Body>
    </Modal>
  );
};

const useProgressAddModal = makeUseModal(ProgressAddModal);

export default useProgressAddModal;
