import React, { useCallback, useState } from 'react';

import { ISelfOnboardingBusiness } from '@src/types/self_onboarding_invitation';

import Table from '@src/components/ui/table';
import { AmountInput } from "@src/components/ui_v2/inputs";
import { CheckGreenIcon } from "@src/components/utils/icomoon";
import MutationStatus from "@src/components/utils/mutation_status";
import { useAchDocumentConfirmMicroDeposit } from "@src/hooks/queries/accounts_payable_ach_documents";

interface IMicroDepositItemProps {
  business: ISelfOnboardingBusiness,
}

const MicroDepositItem = ({
  business,
}: IMicroDepositItemProps): JSX.Element => {
  const [isVerified, setIsVerified] = useState<boolean>(business.dwollaCustomer?.dwollaFundingSource?.isVerified);
  const [amountFirst, setAmountFirst] = useState<string | null>(null);
  const [amountSec, setAmountSec] = useState<string | null>(null);

  const confirmMicroDeposit = useAchDocumentConfirmMicroDeposit();
  const { mutate } = confirmMicroDeposit;

  const handleVerify = useCallback(() => {
    if (!amountFirst || !amountSec) {
      return
    }
    mutate({
      business_id: business.id,
      amount1: amountFirst,
      amount2: amountSec,
      workflow: 'self-onboarding'
    }, {
      onSuccess: () => {
        setIsVerified(true);
      },
    });
  }, [business, mutate, amountFirst, amountSec]);

  return (
    <Table.Row>
      <MutationStatus mutation={ confirmMicroDeposit } />
      <Table.Cell> { business.displayName } </Table.Cell>
      <Table.Cell> { business.dwollaCustomer?.dwollaFundingSource?.name || '' } </Table.Cell>
      <Table.Cell> ***** { business.dwollaCustomer?.dwollaFundingSource?.last4 || '' } </Table.Cell>
      <Table.Cell>
        <AmountInput
          disabled={ isVerified }
          placeholder="$x.x"
          value={ String(amountFirst || '') }
          onChange={ (value) => setAmountFirst(value) }
        />
      </Table.Cell>
      <Table.Cell>
        <AmountInput
          disabled={ isVerified }
          placeholder="$x.x"
          value={ String(amountSec || '') }
          onChange={ (value) => setAmountSec(value) }
        />
      </Table.Cell>
      <Table.Cell className="action-column">
        {
          isVerified ? (
            <CheckGreenIcon fontSize={ 24 } />
          ) : (
            <a
              className="pointer in-purple-1000"
              role="button"
              tabIndex={ -1 }
              onClick={ handleVerify }
            >
              Verify
            </a>
          )
        }
      </Table.Cell>
    </Table.Row>
  );
};

export default MicroDepositItem;
