import React from 'react';

import { Routes, Route } from 'react-router-dom';

import DepositTracking from './deposit_tracking';
import NewSystem from './new_system';
import PaymentProcessorProvider from './payment_processor_provider';
import ReconcilePage from './reconcile';
import ResolveRevenueReport from './resolve_revenue_report';
import RevenueCapture from './revenue_capture';
import RevenueSystemProvider from './revenue_system_provider';
import Root from './root';
import UnassignedRevenue from './unassigned_revenue';

const RevenueCenterRouter = (): JSX.Element | null => {
  return (
    <Routes>
      <Route path="/businesses/:businessId/revenue_service">
        <Route element={ <RevenueSystemProvider /> } path=":revenueSystemId">
          <Route element={ <RevenueCapture /> } path="revenue_capture">
            <Route element={ <ResolveRevenueReport /> } path=":revenueServiceDocumentId/resolve" />
          </Route>
          <Route element={ <DepositTracking /> } path="deposit_tracking" />
          <Route element={ <PaymentProcessorProvider /> } path="reconcile/:paymentProcessorId?">
            <Route index element={ <ReconcilePage /> } />
          </Route>
        </Route>
        <Route element={ <NewSystem /> } path="add_system" />
        <Route element={ <UnassignedRevenue /> } path="unassigned" />
        <Route index element={ <Root /> } />
      </Route>
    </Routes>
  );
};

export default React.memo(RevenueCenterRouter);
