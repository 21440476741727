import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import DeleteAction from './delete_action';
import DetailsAction from './details_action';
import SplitAction from './split_action';
import UndoSplitAction from './undo_split_action';

interface ITransactionActionsMenuProps {
  transaction: ITransactionServiceDocument,
}

const TransactionActionsMenu = ({
  transaction,
}: ITransactionActionsMenuProps) => {
  return (
    <ActionsDropdown widthPercent={ 25 }>
      <DetailsAction transaction={ transaction } />
      <SplitAction transaction={ transaction } />
      <UndoSplitAction transaction={ transaction } />
      <DeleteAction transaction={ transaction } />
    </ActionsDropdown>
  );
};

export default TransactionActionsMenu;
