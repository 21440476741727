import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getReportConfigurations } from '@src/requests/report_service/report_configurations';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';

const useGetReportConfigurations = () => {
  return useQuery<IReportConfiguration[], Error>(
    [QueryKey.reportConfigurations],
    getReportConfigurations,
    {
      staleTime: Infinity,
    },
  );
};

export {
  useGetReportConfigurations,
};
