import React from 'react';

import { IDataExportData } from '@src/types/data_exports';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import DetailsPageTitle from '@src/components/ui/details_page_title';

import AddTransactionAction from './add_transaction_action';
import ExportCSVAction from './export_csv_action';

interface ITransactionsHeaderProps {
  exportParams: IDataExportData['exportParams'],
  isAdmin: boolean,
  isTrash: boolean,
}

const TransactionsHeader = ({
  exportParams,
  isAdmin,
  isTrash,
}: ITransactionsHeaderProps): JSX.Element => {
  return (
    <DetailsPageTitle title="Transactions">
      { !isTrash && isAdmin && (
        <AddTransactionAction />
      ) }
      <ActionsDropdown>
        <ExportCSVAction exportParams={ exportParams } />
      </ActionsDropdown>
    </DetailsPageTitle>
  );
};

export default React.memo(TransactionsHeader);
