import React from 'react';

import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';
import { camelizeKeys } from '@src/utils/transform_keys';

import AuthorizedSignatoryView from './authorized_signatory/authorized_signatory_view';
import BillingInfoList from './billing_info_details/billing_info_list';
import FinDetailsView from './connect_fin_details/fin_details_view';
import ConnectQboList from './connect_qbo_details/connect_qbo_list';
import MicroDepositList from './micro_deposit_details/micro_deposit_list';
import RequestDocumentView from './request_document_details/request_document_view';

interface ISelfOnboardingMainViewProps {
  step: number,
  invitation: any,
  isSuccess: boolean,
}

const STEP_REQUEST_DOCUMENT = 0;
const STEP_CONNECT_QBO = 1;
const STEP_AUTHORIZED_SIGN = 2;
const STEP_BILLING_INFO = 3;
const STEP_MICRO_DEPOSIT = 4;
const STEP_CONNECT_FIN = 5;
// const STEP_KYC_DOCUMENTS = 6;

const SelfOnboardingMainView = ({
  step,
  invitation,
  isSuccess,
}: ISelfOnboardingMainViewProps): JSX.Element | null => {
  const selfOnboardingInvitation = camelizeKeys(invitation) as ISelfOnboardingInvitation;
  switch (step) {
    case STEP_REQUEST_DOCUMENT:
      return (
        <RequestDocumentView
          invitation={ selfOnboardingInvitation }
          isSuccess={ isSuccess }
        />
      );
    case STEP_CONNECT_QBO:
      return <ConnectQboList invitation={ selfOnboardingInvitation } isSuccess={ isSuccess } />;
    case STEP_AUTHORIZED_SIGN:
      return <AuthorizedSignatoryView invitation={ selfOnboardingInvitation } isSuccess={ isSuccess } />;
    case STEP_BILLING_INFO:
      return <BillingInfoList invitation={ selfOnboardingInvitation } isSuccess={ isSuccess } />;
    case STEP_MICRO_DEPOSIT:
      return <MicroDepositList invitation={ selfOnboardingInvitation } isSuccess={ isSuccess } />;
    case STEP_CONNECT_FIN:
      return <FinDetailsView invitation={ selfOnboardingInvitation } isSuccess={ isSuccess } />;
    default:
      return null;
  }
};

export default SelfOnboardingMainView;
