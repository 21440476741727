import React, { useState } from 'react';

import isNull from 'lodash/isNull';

import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';
import { LDFeatureForBusinessEnabled, LDFeatureValueForBusiness } from '@src/utils/config';
import RevenueServiceProvider from '@src/components/providers/revenue_service_provider';

import BusinessItem from './revamp_items/business_item';
import ExpenseItem from './revamp_items/expense_item';
import ReconciliationItem from './revamp_items/reconciliation_item';
import ReportsItem from './revamp_items/reports_item';
import RevenueItem from './revamp_items/revenue_item';
import { activatedModuleHeader } from './utils';

import styles from '../styles.module.scss';

interface IModuleHeaderActionsProps {
  businessId: TID,
  currentModulePath?: string,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  info: IBusinessServicesInfo,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

const GlobalNavigationHeaderActions = ({
  businessId,
  currentModulePath,
  focusToMyClient,
  isGlobalNavigationEnabled
}: IModuleHeaderActionsProps): JSX.Element | null => {
  const [bankStatementEnabled, setBankStatementEnabled] = useState<string | boolean | undefined>(
    LDFeatureValueForBusiness(
      window.Docyt.Common.Constants.BANK_STATEMENTS_FLAG,
      String(businessId),
    ),
  );

  const { data: info } = useGetBusinessServicesInfo(businessId);

  const sunsetVaultFeatureFlagEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.OTHER_STATEMENTS_DOC_REQUEST_FLAG);

  const activeItem = activatedModuleHeader(currentModulePath, sunsetVaultFeatureFlagEnabled, isGlobalNavigationEnabled && isNull(focusToMyClient));

  if (bankStatementEnabled === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.BANK_STATEMENTS_FLAG,
      String(businessId),
    ).then((res) => {
      setBankStatementEnabled(res);
    });
  }

  if (!info) {
    return null;
  }

  const components = [
    { component: BusinessItem, key: 'BusinessItem' },
    { component: ExpenseItem, key: 'ExpenseItem' },
    { component: RevenueItem, key: 'RevenueItem' },
    { component: ReconciliationItem, key: 'ReconciliationItem' },
    { component: ReportsItem, key: 'ReportsItem' },
  ];
  
  const renderComponent = (Component: React.ComponentType<IServiceMenuProps>, key: string) => (
    <Component
      key={ key }
      activeItem={ activeItem }
      businessId={ businessId }
      focusToMyClient={ focusToMyClient }
      info={ info }
      isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
    />
  );
  
  return (
    <div className={ styles['service-menu-page'] }>
      {components.map(({ component: Component, key }) => (
        key === 'RevenueItem' ? (
          <RevenueServiceProvider key={ key } businessId={ businessId } isGlobalNavigationEnabled={ isGlobalNavigationEnabled }>
            {renderComponent(Component, key)}
          </RevenueServiceProvider>
        ) : (
          renderComponent(Component, key)
        )
      ))}
    </div>
  );
};

export default GlobalNavigationHeaderActions;
