import React, { FC } from 'react';

import toastr from '@lib/toastr';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import JournalDrawer from '@src/components/reconciliation_center/journal_entries/header/journal_drawer';
import useDrawer from '@src/components/reconciliation_center/journal_entries/header/use_drawer';
import SingleJournalEntryDownload
  from '@src/components/reconciliation_center/journal_entry/single_journal_entry_download';

import Table from '../journal_entries/header/table';

import detailStyles from './styles.module.scss';

const renderQboLink = (entry: IAdjustmentEntry) => {
  if (entry.qboError) {
    return (
      <span className="display-inline-flex in-grey-700 align-items-center" title={ entry.qboError }>
        <span className="icon-qbo-error font-18" />
        <span className="font-16 m-l-10 m-r-10">View in QuickBooks</span>
      </span>
    );
  }

  return (
    <a className="display-inline-flex in-green-600 align-items-center" href={ entry.qboLink?.qboLink } rel="noreferrer" target="_blank">
      <span className="icon-qbo-on font-18" />
      <span className="font-16 m-l-10 m-r-10">View in QuickBooks</span>
    </a>
  );
};

const EditPanel: FC<{data: IAdjustmentEntry}> = ({ data }) => {
  const { isOpen, open: openModal, close } = useDrawer();

  const { source, fromQbo } = data;

  const openDocument = () => {
    if (source.id) {
      window.open(`/document/${source.id}`);
    } else {
      toastr.error('Document not found', 'Error');
    }
  };

  const buttonPanel = source?.type === 'Normal' ? (
    <>
      <button className={ detailStyles['edit-btn'] } type="button" onClick={ openModal }>Edit</button>
      <JournalDrawer drawerControl={ { isOpen, open: openModal, close } } title="Edit Journal Entry">
        <Table close={ close } editData={ data } />
      </JournalDrawer>
    </>
  ) : (
    <button className={ detailStyles['edit-btn'] } type="button" onClick={ openDocument }>
      View
      {' '}
      { source?.type }
    </button>
  );

  return (
    <div className={ detailStyles['edit-container'] }>
      { renderQboLink(data) }
      {
        !fromQbo && (
          <>
            <SingleJournalEntryDownload id={ data.id } />
            { buttonPanel }
          </>
        )
      }
    </div>
  );
};

export default EditPanel;
