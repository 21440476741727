module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<span class="font-24 font-bold in-blue-900 m-l-10 ');
    
      __out.push(__sanitize(this.last_unread_message ? '' : 'invisible'));
    
      __out.push('">&bull;</span>\n<div class="p-l-10 first-notification-column chat-item-clickable">\n  <div class="middle-avatar-wrapper border-gray">\n    ');
    
      if (this.avatarUrl) {
        __out.push('\n    <img src="');
        __out.push(__sanitize(this.avatarUrl));
        __out.push('">\n    ');
      } else {
        __out.push('\n    <i class="icon icon-smile in-blue-400 font-48"></i>\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n<div class="chat-item-panel chat-item-clickable ');
    
      if (!this.last_unread_message) {
        __out.push(__sanitize('in-grey-500'));
      }
    
      __out.push('">\n  <div class=\'notification-time-wrapper m-t-10\'>\n    <p class="font-12 in-grey-900 m-b-0">');
    
      __out.push(__sanitize(this.created_at));
    
      __out.push('</p>\n  </div>\n  <h6><span class="chat-title">');
    
      __out.push(__sanitize(this.business_name));
    
      __out.push('</span> <span class="chat-sender-text">(');
    
      __out.push(__sanitize(this.last_unread_message_sender_name || this.last_message_sender_name));
    
      __out.push(')</span></h6>\n  <h5 class="multiline-ellipsis-text message-js"></h5>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}