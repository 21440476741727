import React, { useCallback, useState } from 'react';

import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';

import { Button } from '@src/components/ui/buttons';
import Table from '@src/components/ui/table/table';
import { SuccessIcon } from '@src/components/utils/icomoon';

import ConnectQboListItem from './connect_qbo_item';

import styles from './styles.module.scss';

interface IConnectQboListProps {
  invitation: ISelfOnboardingInvitation,
  isSuccess: boolean,
}

const ConnectQboList = ({
  invitation,
  isSuccess,
}: IConnectQboListProps) => {
  const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(isSuccess);

  const handleSubmit = useCallback(() => {
    Backbone.history.navigate('/self_onboarding?step=1&success=1', { trigger: false, replace: true });
    setShowSuccessBanner(true);
  }, [setShowSuccessBanner]);

  if (showSuccessBanner) {
    return (
      <div className={ styles['qbo-success'] }>
        <SuccessIcon fontSize={ 80 } mt={ 80 } />
        <h2 className="font-18 in-black m-t-30">Successfully connected the accounting software.</h2>
        <span className="m-t-10">As a next step,</span>
        <span className="m-t-5">we’ll send an email to the authorized signatory and</span>
        <span className="m-t-5">billing admin within next 24 hours.</span>
      </div>
    );
  }

  return (
    <div className={ styles['self-onboarding-qbo-container'] }>
      <div className="tasks-container">
        <h2>Connect Accounting Software</h2>
        <p className="font-11 in-grey-1050">
          Docyt can automatically update a connected QuickBooks Online account. Seamlessly connect your QBO with Docyt for
          streamlined and enhanced accounting.
        </p>
        <div className="tasks-view">
          <Table>
            <Table.Body>
              {
              invitation.businesses.map((business) => (
                <ConnectQboListItem key={ business.id } business={ business } />
              ))
            }
            </Table.Body>
          </Table>
        </div>
      </div>
      <div className="setup-client-footer">
        <Button
          className="pull-right bg-purple-1000 in-white width-180px"
          data-color="$purple-1000"
          onClick={ handleSubmit }
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ConnectQboList;
